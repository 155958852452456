import { useDroppable } from '@dnd-kit/core'
import { SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import React from 'react'
import DndCard from './DndCard'
import { styled } from '@mui/material'
import { ProductionBoardRecord } from '../../types/production'

const ColumnContainer = styled('div')(() => ({
  borderRadius: '4px',
  padding: '10px',
  overflowY: 'auto',

  boxSizing: 'border-box',
  '&::-webkit-scrollbar-vertical, &::-webkit-scrollbar-y': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
}))

const ColumnHeader = styled('p')(() => ({
  textAlign: 'left',
  fontWeight: '500',
  color: '#000000',
}))

const CardsContainer = styled('div')(() => ({
  overflowY: 'auto',
}))

interface DnDColumnProps {
  id: string
  label: string
  items: ProductionBoardRecord[]
  type: 'painting' | 'sandblasting'
}

const DnDColumn = ({ id, label, items, type }: DnDColumnProps) => {
  const { setNodeRef } = useDroppable({ id })

  const getColumnStyle = (columnId: string) => {
    const defaults = {
      backgroundColor: '#deedf6',
      minWidth: '20%',
      width: '20%',
      flex: '1 0 auto',
    }

    if (
      (columnId === 'done' && type === 'painting') ||
      columnId === 'toInvoiced'
    ) {
      return {
        ...defaults,
        border: '2px solid #61bb61',
      }
    } else if (columnId === 'rejected') {
      return {
        ...defaults,
        border: '2px solid #e97575',
      }
    } else if (columnId === 'invoiced') {
      return {
        ...defaults,
        border: '2px solid #0B73B0FF',
      }
    } else {
      return {
        ...defaults,
        backgroundColor: '#deedf6',
      }
    }
  }

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      <ColumnContainer style={getColumnStyle(id)} ref={setNodeRef}>
        <ColumnHeader>{label}</ColumnHeader>
        <CardsContainer>
          {items.map((item) => (
            <DndCard key={item.id} item={item} type={type} />
          ))}
        </CardsContainer>
      </ColumnContainer>
    </SortableContext>
  )
}

export default DnDColumn
