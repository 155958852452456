import React from 'react'
import { RouteObject } from 'react-router-dom'
import App from '../App'
import LoginPage from '../pages/LoginPage'
import ProtectedRoute from '../components/ProtectedRoute'
import OrdersPage from '../pages/Orders/OrdersPage'
import DefaultLayout from '../components/DefaultLayout'
import SettingsPage from '../pages/Settings/SettingsPage'
import OrderDetailPage from '../pages/Orders/OrderDetailPage'
import AddOrderPage from '../pages/Orders/AddOrderPage'
import QRScannerPage from '../pages/QRScanner/QRScannerPage'
import NotFound from '../pages/NotFound'
import ContractorsPage from '../pages/Contractors/ContractorsPage'
import AddContractorPage from '../pages/Contractors/AddContractorPage'
import ContractorDetailPage from '../pages/Contractors/ContractorDetailPage'
import DocumentsPage from '../pages/Documents/DocumentsPage'
import PaintingBoardPage from '../pages/Production/PaintingBoardPage'
import AccountPage from '../pages/Account/AccountPage'
import UsersPage from '../pages/Users/UsersPage'
import AddUserPage from '../pages/Users/AddUserPage'
import SandblastingBoardPage from '../pages/Production/SandblastingBoardPage'
import DocumentDetailsPage from '../pages/Documents/DocumentDetailsPage'

const routes: RouteObject[] = [
  {
    path: '',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: '/',
        element: <DefaultLayout />,
        children: [
          {
            index: true,
            element: <ProtectedRoute children={<OrdersPage />} />,
          },
          {
            path: 'settings',
            element: <ProtectedRoute children={<SettingsPage />} />,
          },
          {
            path: 'account',
            element: <ProtectedRoute children={<AccountPage />} />,
          },
          {
            path: 'orders',
            children: [
              { path: ':ciNumber', element: <DocumentDetailsPage /> },
              { path: ':ciNumber/:orderId', element: <OrderDetailPage /> },
              {
                path: 'add',
                element: <ProtectedRoute children={<AddOrderPage />} />,
              },
              {
                path: 'edit/:ciNumber',
                element: <ProtectedRoute children={<AddOrderPage />} />,
              },
            ],
          },

          {
            path: 'scan-qr',
            element: <ProtectedRoute children={<QRScannerPage />} />,
          },
          {
            path: 'contractors',
            children: [
              {
                index: true,
                element: <ProtectedRoute children={<ContractorsPage />} />,
              },
              {
                path: 'add',
                element: <ProtectedRoute children={<AddContractorPage />} />,
              },
              {
                path: ':contractorId',
                element: <ProtectedRoute children={<ContractorDetailPage />} />,
              },
              {
                path: 'edit/:contractorId',
                element: <ProtectedRoute children={<AddContractorPage />} />,
              },
            ],
          },
          {
            path: 'production',
            children: [
              {
                path: 'painting',
                element: <ProtectedRoute children={<PaintingBoardPage />} />,
              },
              {
                path: 'sandblasting',
                element: (
                  <ProtectedRoute children={<SandblastingBoardPage />} />
                ),
              },
            ],
          },
          {
            path: 'documents',
            element: <ProtectedRoute children={<DocumentsPage />} />,
          },
          {
            path: 'users',
            children: [
              {
                index: true,
                element: <ProtectedRoute children={<UsersPage />} />,
              },
              {
                path: 'add',
                element: <ProtectedRoute children={<AddUserPage />} />,
              },
              {
                path: 'edit/:userId',
                element: <ProtectedRoute children={<AddUserPage />} />,
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]

export default routes
