import axios from 'axios'

const api = axios.create()

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers.Authorization = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

export const callApi = async <T>(
  url: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' = 'GET',
  body?: unknown,
): Promise<T> => {
  try {
    if (method === 'GET') {
      return await api.get(url)
    } else if (method === 'POST') {
      return await api.post(url, body)
    } else if (method === 'PUT') {
      return await api.put(url, body)
    } else if (method === 'PATCH') {
      return await api.patch(url, body)
    } else if (method === 'DELETE') {
      return await api.delete(url, {
        data: body,
      })
    } else {
      throw new Error('Invalid HTTP method')
    }
  } catch (error) {
    // @ts-expect-error error dont have response
    throw error.response.data || error.message
  }
}
