import React, { CSSProperties, useEffect, useState } from 'react'
import { Column } from '../../types/customTableTypes'
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { map, slice } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import { searchParamsToObject } from '../../utils/helpers'

interface MTableProps<T> {
  columns: Column<T>[]
  data: T[]
  isLoading: boolean
  customDataFilter?: (items: T[]) => T[]
  style?: CSSProperties
}

const Cell = styled(TableCell)(() => ({
  padding: '0.25rem 1rem',
}))

const MTable = <T,>({
  columns,
  isLoading,
  data,
  customDataFilter,
  style,
}: MTableProps<T>) => {
  const rowsPerPage = 10
  const [page, setPage] = useState<number>(0)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page)
    setSearchParams({
      ...searchParamsToObject(searchParams),
      page: String(page),
    })
  }

  useEffect(() => {
    if (data.length) {
      setPage(parseInt(searchParams.get('page') || '0'))
    }
  }, [searchParams, data])

  return (
    <>
      <TableContainer style={style} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {map(columns, (column, index) => (
                <TableCell
                  width={column.width}
                  key={index}
                  align={column.align}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {map(
                slice(
                  customDataFilter ? customDataFilter(data) : data,
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                ),
                (record, recordIndex) => (
                  <TableRow
                    key={recordIndex}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    style={{ height: '50px' }}
                  >
                    {map(columns, (column, columnIndex) => (
                      <Cell
                        component={column.component}
                        scope={column.scope}
                        key={columnIndex}
                      >
                        {column.onRender
                          ? column.onRender(record, recordIndex)
                          : String(record[column.key])}
                      </Cell>
                    ))}
                  </TableRow>
                ),
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={customDataFilter ? customDataFilter(data).length : data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  )
}

export default MTable
