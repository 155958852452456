import { create } from 'zustand'
import {
  ProductionBoardColumn,
  ProductionBoardRecord,
} from '../types/production'
import { callApi } from '../utils/callApi'
import useAuthStore from './useAuthStore'

interface Store {
  paintingBoardColumns: ProductionBoardColumn[]
  sandblastingBoardColumns: ProductionBoardColumn[]
  getProductionBoardColumns: (
    type: 'painting' | 'sandblasting',
  ) => Promise<void>
  updateProductionStatus: (
    type: 'painting' | 'sandblasting',
    item: ProductionBoardRecord,
  ) => Promise<void>
  splitOrderTicker: (data: object) => Promise<void>
  changeOrder: (
    type: 'painting' | 'sandblasting',
    data: ProductionBoardRecord[],
  ) => Promise<void>
}

const useProductionStore = create<Store>((set) => ({
  paintingBoardColumns: [],
  sandblastingBoardColumns: [],

  getProductionBoardColumns: async (type) => {
    await callApi<{ data: ProductionBoardColumn[]; message: string }>(
      `/api/production/${type}`,
      'GET',
    ).then((res) => {
      if (type === 'painting') {
        set(() => ({ paintingBoardColumns: res.data }))
      } else {
        set(() => ({ sandblastingBoardColumns: res.data }))
      }
    })
  },

  updateProductionStatus: async (type, item) => {
    await callApi(`/api/production/${type}/${item.id}`, 'PATCH', {
      modifiedBy: useAuthStore.getState().me!.login,
      ...(type === 'painting'
        ? { paintingStatus: item.paintingStatus }
        : { sandblastingStatus: item.sandblastingStatus }),
      ...(item.rejectedMessage && { rejectedMessage: item.rejectedMessage }),
    })
  },

  splitOrderTicker: async (data) => {
    await callApi(`/api/production/split`, 'POST', {
      modifiedBy: useAuthStore.getState().me!.login,
      ...data,
    })
  },

  changeOrder: async (type, data) => {
    await callApi(`/api/production/${type}/order`, 'POST', {
      data,
      modifiedBy: useAuthStore.getState().me!.login,
    })
  },
}))

export default useProductionStore
