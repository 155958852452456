import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { OrderImage } from '../types/image'
import { NotificationService } from '../service/NotificationService'
import { filter } from 'lodash'

interface Store {
  orderImages: OrderImage[][]
  imagesToUpload: File[][]
  addNewImagesContainer: () => void
  removeImagesContainer: (index: number) => void
  setImagesToUpload: (imagesToUpload: File[], index: number) => void
  getSelectedOrderImages: (ciNumber?: string) => Promise<OrderImage[][]>
  getImagesByOrderId: (
    ciNumber?: string,
    orderId?: number,
  ) => Promise<OrderImage[]>
  uploadImage: (orderId: number, index: number) => Promise<number>
  deleteImage: (orderIndex: number, imageId?: number) => Promise<number>
  removeImageToUpload: (imageToDelete: File, index: number) => void
  printImage: (imageId: number) => void
}

const useImagesStore = create<Store>((set, get) => ({
  orderImages: [],
  imagesToUpload: [],

  addNewImagesContainer: () => {
    set((state) => {
      const newImagesState = state.imagesToUpload

      newImagesState.push([])

      return {
        imagesToUpload: newImagesState,
      }
    })
  },

  removeImagesContainer: (index) => {
    set((state) => {
      return {
        imagesToUpload: filter(
          state.imagesToUpload,
          (_images, i) => index !== i,
        ),
      }
    })
  },

  setImagesToUpload: (imagesToUpload, index) => {
    set((state) => {
      const newImagesState = state.imagesToUpload

      newImagesState[index] = [
        ...state.imagesToUpload[index],
        ...imagesToUpload,
      ]

      return {
        imagesToUpload: newImagesState,
      }
    })
  },

  removeImageToUpload: (imageToDelete, index) => {
    set((state) => {
      const newImagesState = state.imagesToUpload

      newImagesState[index] = filter(
        newImagesState[index],
        (image) => image !== imageToDelete,
      )

      return {
        imagesToUpload: newImagesState,
      }
    })
  },

  getSelectedOrderImages: async (ciNumber) => {
    await callApi<{ data: OrderImage[][] }>(
      `/api/images/${ciNumber}`,
      'GET',
    ).then((res) => {
      set(() => ({
        orderImages: res.data,
        imagesToUpload: Array.from({ length: res.data.length }, () => []),
      }))
    })

    return get().orderImages
  },

  getImagesByOrderId: async (ciNumber, orderId) =>
    await callApi<{ data: OrderImage[] }>(
      `/api/images/${ciNumber}/${orderId}`,
      'GET',
    ).then((res) => {
      return res.data
    }),

  uploadImage: async (orderId, index) => {
    if (get().imagesToUpload.length > 0) {
      const formData = new FormData()

      get().imagesToUpload[index].forEach((image) => {
        formData.append('images', image)
      })

      return await callApi<{ data: { message: string }; status: number }>(
        `/api/images/${orderId}`,
        'POST',
        formData,
      )
        .then((res) => {
          set(() => ({ imagesToUpload: [] }))
          return res.status
        })
        .catch((error) => {
          NotificationService.showNotification(error.message, 'error')
          return error.status
        })
    }
  },

  deleteImage: async (orderIndex, imageId) =>
    await callApi<{ data: { message: string }; status: number }>(
      `/api/images/${imageId}`,
      'DELETE',
    )
      .then((res) => {
        set((state) => {
          const newOrderImages = state.orderImages

          newOrderImages[orderIndex] = filter(
            newOrderImages[orderIndex],
            (image) => image.id !== imageId,
          )

          return {
            orderImages: newOrderImages,
          }
        })
        return res.status
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),

  printImage: (imageId) => callApi(`/api/images/print/${imageId}`, 'POST'),
}))

export default useImagesStore
