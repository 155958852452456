import { Grid2 } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'
import MInput from '../material/MInput'
import MAutocomplete, { AutoCompleteOption } from '../material/MAutocomplete'
import {
  finishesOptions,
  glossLevelOptions,
  paintTypeOptions,
  quantityTypes,
} from '../../utils/selectorsOptions'
import MCheckbox from '../material/MCheckbox'
import React from 'react'
import { Grn } from '../../types/order'
import { ralColorsPalette } from '../../utils/ralColorsPalette'
import { get } from 'lodash'
import { FieldWrapper } from '../../styles/styles'

interface OrderDetailsTabProps {
  methods: UseFormReturn<Grn>
  orderIndex: number
}

const checkboxOptions = [
  { value: 'yes', label: 'Tak' },
  { value: 'no', label: 'Nie' },
]

export default function OrderDetailsTab({
  methods,
  orderIndex,
}: OrderDetailsTabProps) {
  const { setValue, control, watch } = methods

  const sandblasting = watch(`orders.${orderIndex}.sandblasting`)

  const onlySandblasting = sandblasting === 'only'

  const ralColorsOptions: AutoCompleteOption[] = Object.keys(
    ralColorsPalette,
  ).map((value) => ({ value, label: value }))

  return (
    <Grid2 direction="column" container sx={{ flex: 1 }}>
      <Grid2 container direction="row" gap={2}>
        <Controller
          name={`orders.${orderIndex}.quantity`}
          control={control}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <MInput
              style={{ width: 'calc(30% - 11px)' }}
              required
              variant="outlined"
              label="Ilość"
              type="number"
              value={value}
              onChange={onChange}
              helperText={get(errors, `orders.${orderIndex}.quantity.message`)}
            />
          )}
        />
        <Controller
          name={`orders.${orderIndex}.quantityType`}
          control={control}
          render={({ field: { value } }) => (
            <MAutocomplete
              style={{ width: 'calc(20% - 11px)' }}
              label="Typ"
              value={value}
              onChange={(value: string) =>
                setValue(`orders.${orderIndex}.quantityType`, value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              options={quantityTypes}
            />
          )}
        />
        <Controller
          name={`orders.${orderIndex}.product`}
          control={control}
          render={({ field: { value }, formState: { errors } }) => (
            <MInput
              required
              style={{ width: 'calc(50% - 11px)' }}
              variant="outlined"
              label="Nazwa towaru"
              type="text"
              value={value}
              onChange={(event) =>
                setValue(`orders.${orderIndex}.product`, event.target.value, {
                  shouldDirty: true,
                  shouldValidate: true,
                })
              }
              helperText={get(errors, `orders.${orderIndex}.product.message`)}
              inputProps={{
                maxLength: 40,
              }}
            />
          )}
        />
      </Grid2>
      <Grid2 container direction="row" gap={2}>
        <FieldWrapper style={{ flex: 1 }}>
          <Controller
            name={`orders.${orderIndex}.ralColor`}
            control={control}
            render={({ field: { value } }) => (
              <MAutocomplete
                freeSolo
                disabled={onlySandblasting}
                fullWidth
                value={value}
                onChange={(value: string) =>
                  setValue(`orders.${orderIndex}.ralColor`, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                options={ralColorsOptions}
                label="Kolor RAL"
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper style={{ flex: 1 }}>
          <Controller
            name={`orders.${orderIndex}.glossLevel`}
            control={control}
            render={({ field: { value } }) => (
              <MAutocomplete
                disabled={onlySandblasting}
                value={value}
                label="Stopień połysku"
                onChange={(value: string) =>
                  setValue(`orders.${orderIndex}.glossLevel`, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                options={glossLevelOptions}
                fullWidth
              />
            )}
          />
        </FieldWrapper>
      </Grid2>
      <Grid2 container direction="row" gap={2}>
        <FieldWrapper style={{ flex: 1 }}>
          <Controller
            name={`orders.${orderIndex}.finishing`}
            control={control}
            render={({ field: { value } }) => (
              <MAutocomplete
                disabled={onlySandblasting}
                value={value}
                label="Wykończenie"
                onChange={(value: string) =>
                  setValue(`orders.${orderIndex}.finishing`, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                options={finishesOptions}
                fullWidth
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper style={{ flex: 1 }}>
          <Controller
            name={`orders.${orderIndex}.paintType`}
            control={control}
            render={({ field: { value } }) => (
              <MAutocomplete
                disabled={onlySandblasting}
                value={value}
                label="Rodzaj farby"
                onChange={(value: string) =>
                  setValue(`orders.${orderIndex}.paintType`, value, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                options={paintTypeOptions}
                fullWidth
              />
            )}
          />
        </FieldWrapper>
      </Grid2>

      <Grid2 container direction="row" gap={4}>
        <FieldWrapper style={{ flex: 1 }}>
          <Controller
            name={`orders.${orderIndex}.sandblasting`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <MCheckbox
                onChange={onChange}
                id="sandblasting"
                label="Piaskowanie"
                defaultValue={value}
                options={[
                  ...checkboxOptions,
                  { value: 'only', label: 'Tylko piaskowanie' },
                ]}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper style={{ flex: 1 }}>
          <Controller
            name={`orders.${orderIndex}.base`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <MCheckbox
                disabled={onlySandblasting}
                onChange={onChange}
                id="base"
                label="Podkład"
                defaultValue={value}
                options={checkboxOptions}
              />
            )}
          />
        </FieldWrapper>
        <FieldWrapper style={{ flex: 1 }}>
          <Controller
            name={`orders.${orderIndex}.grinding`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <MCheckbox
                disabled={onlySandblasting}
                onChange={onChange}
                id="grinding"
                label="Szlifowanie"
                defaultValue={value}
                options={checkboxOptions}
              />
            )}
          />
        </FieldWrapper>
      </Grid2>
      <Grid2>
        <FieldWrapper style={{ width: '50%' }}>
          <Controller
            name={`orders.${orderIndex}.description`}
            control={control}
            render={({ field: { value, onChange }, formState: { errors } }) => (
              <MInput
                fullWidth
                variant="outlined"
                label="Opis / Uwagi (Max 150 znaków)"
                type="text"
                value={value}
                onChange={onChange}
                helperText={errors.ciNumber?.message}
                multiline
                maxRows={4}
                minRows={4}
                inputProps={{
                  maxLength: 150,
                }}
              />
            )}
          />
        </FieldWrapper>
      </Grid2>
    </Grid2>
  )
}
