import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  styled,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import usePrinterStore from '../store/usePrinterStore'
import { Grn } from '../types/order'
import MInput from './material/MInput'
import { toDataURL } from '../utils/helpers'
import useOrdersStore from '../store/useOrdersStore'
import { filter, forEach, map, pick, some } from 'lodash'
import useAppStore from '../store/useAppStore'

interface ConfirmPrintDialogProps {
  open: boolean
  closeDialog: () => void
  ciNumber?: string | null
  orderId?: number
}

const StyledDialogContent = styled(DialogContent)(() => ({
  paddingTop: '1rem !important',
  padding: '1.25rem',
  width: '300px',
  overflow: 'auto',
  maxHeight: '400px',
  gap: '2rem',
  display: 'flex',
  flexDirection: 'column',
}))

const ConfirmPrintLabelDialog = ({
  open,
  closeDialog,
  ciNumber,
  orderId,
}: ConfirmPrintDialogProps) => {
  const { printLabel } = usePrinterStore()
  const { getOrdersByCiNumber } = useOrdersStore()
  const [numberOfCopy, setNumberOfCopy] = useState<string[]>([])
  const [confirmCount, setConfirmCount] = useState<boolean>(false)
  const [signature, setSignature] = useState<string | undefined>(undefined)
  const [grnToPrint, setGrnToPrint] = useState<Grn | null>(null)
  const { setIsOpen } = useAppStore()

  const handleTriggerPrint = () => {
    if (grnToPrint) {
      const basicInfo = pick(grnToPrint, [
        'phoneNumber',
        'date',
        'ciNumber',
        'road',
        'city',
        'postalCode',
        'taxId',
        'name',
      ])
      forEach(grnToPrint.orders, (order, index) => {
        printLabel({
          ...basicInfo,
          ...order,
          numberOfCopy: numberOfCopy[index],
          signature,
        })
      })

      setSignature(undefined)
      closeDialog()
      setIsOpen(true)
    }
  }

  useEffect(() => {
    if (ciNumber) {
      getOrdersByCiNumber(ciNumber).then((res) => {
        const { data } = res

        const filteredData = data

        if (orderId) {
          filteredData.orders = filter(
            filteredData.orders,
            (order) => order.id === orderId,
          )
        }

        setGrnToPrint(filteredData)
        if (filteredData?.signature) {
          toDataURL(filteredData.signature).then((res) =>
            setSignature(res as string),
          )
        }

        forEach(filteredData.orders, (order) => {
          if (order?.quantity) {
            if (order?.quantityType === 'pieces') {
              setNumberOfCopy((prevState) => [
                ...prevState,
                String(order.quantity),
              ])
            } else {
              setNumberOfCopy((prevState) => [...prevState, String(1)])
            }
          }
        })
      })
    }
  }, [ciNumber])

  return (
    <Dialog open={open}>
      <DialogTitle>Drukowanie</DialogTitle>
      <StyledDialogContent>
        {grnToPrint &&
          map(grnToPrint.orders, (order, index) => (
            <Grid2 container direction="row" gap={2} key={index}>
              <MInput
                sx={{ width: '300px' }}
                label={`Liczba kopii ${order.product}`}
                type="number"
                defaultValue={numberOfCopy[index]}
                onChange={(e) =>
                  setNumberOfCopy((prevState) => {
                    const stateCopy = prevState
                    stateCopy[index] = e.target.value

                    return stateCopy
                  })
                }
                helperText="Liczba kopii jest automatycznie uzupełniana z danych formularza"
              />
              {order.quantity >= 10 && (
                <MInput
                  sx={{ width: '300px' }}
                  label={`Potwierdź liczbę kopii ${order.product}`}
                  type="number"
                  defaultValue=""
                  onChange={(e) =>
                    setConfirmCount(
                      Number(numberOfCopy[index]) === Number(e.target.value),
                    )
                  }
                />
              )}
            </Grid2>
          ))}
      </StyledDialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Anuluj</Button>
        <Button
          disabled={
            (some(numberOfCopy, (number) => Number(number) >= 10) &&
              !confirmCount) ||
            signature === null
          }
          onClick={handleTriggerPrint}
        >
          Drukuj
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmPrintLabelDialog
