import { string, number, object, ref, array } from 'yup'

function isValidIPv4(ip: string): boolean {
  const ipv4Regex =
    /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
  return ipv4Regex.test(ip)
}

export const loginValidation = object().shape({
  login: string().required('Login jest wymagany'),
  password: string().required('Hasło jest wymagane'),
})

export const updateCurrentUserValidation = object().shape({
  login: string().required('Login jest wymagany'),
  password: string().required('Nowe hasło jest wymagane'),
  confirmPassword: string()
    .oneOf([ref('password')], 'Hasła muszą być takie same')
    .required('Potwierdzenie hasła jest wymagane'),
})

export const updateSettingsValidation = object().shape({
  printerIpAddress: string()
    .test('is-valid-ip', 'Błędny adres IP', (value) => {
      return value ? isValidIPv4(value) : false
    })
    .required('Adres IP jest wymagany'),
})

export const orderValidation = object().shape({
  quantity: number()
    .transform((value, originalValue) =>
      originalValue === '' ? undefined : value,
    )
    .typeError('Ilość musi być liczbą')
    .required('Ilość jest wymagana'),
  quantityType: string().required('Typ jest wymagany'),
  product: string().required('Produkt jest wymagany'),
  ralColor: string(),
  glossLevel: string(),
  finishing: string(),
  paintType: string(),
  sandblasting: string(),
  base: string(),
  grinding: string(),
  description: string().nullable(),
  ciNumber: string(),
  id: number(),
})

export const grnValidation = object().shape({
  name: string().required('Nazwa jest wymagana'),
  taxId: string(),
  postalCode: string()
    .required('Kod pocztowy jest wymagany')
    .matches(/^\d{2}-\d{3}$/, 'Kod pocztowy musi być w formacie 00-000'),
  city: string().required('Miasto jest wymagane'),
  road: string().required('Ulica jest wymagana'),
  ciNumber: string().required('PZ jest wymagany'),
  date: number().required('Data jest wymagana'),
  phoneNumber: string()
    .required('Numer telefonu jest wymagany')
    .matches(
      /^(\d{3}-\d{3}-\d{3}|\d{9})$/,
      'Numer telefonu musi być w formacie 000-000-000 lub 000000000',
    ),
  signature: string(),
  orders: array().of(orderValidation),
})

export const addContractorValidation = object().shape({
  name: string().required('Nazwa jest wymagany'),
  taxId: string(),
  postalCode: string()
    .required('Kod pocztowy jest wymagany')
    .matches(/^\d{2}-\d{3}$/, 'Kod pocztowy musi być w formacie 00-000'),
  city: string().required('Miastko jest wymagane'),
  road: string().required('Ulica jest wymagana'),
  phoneNumber: string()
    .required('Numer telefonu jest wymagane')
    .matches(
      /^(\d{3}-\d{3}-\d{3}|\d{9})$/,
      'Numer telefonu musi być w formacie 000-000-000 lub 000000000',
    ),
  email: string().email('E-mail musi zawierać "@"'),
})

export const addRejectionComment = object().shape({
  rejectedMessage: string().required('Komentarz jest wymagany'),
})

export const splitTicket = object().shape({
  splitValue: number().required('Wartość jest wymagana'),
})

export const addUserValidation = object().shape({
  login: string().required('Nazwa jest wymagana'),
  password: string().required('Hasło jest wymagane'),
  confirmPassword: string()
    .oneOf([ref('password')], 'Hasła muszą być takie same')
    .required('Potwierdzenie hasła jest wymagane'),
  role: string().required('Rola jest wymagana'),
})
