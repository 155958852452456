import React, { CSSProperties, useEffect, useState } from 'react'
import { Column } from '../../types/customTableTypes'
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material'
import { map, slice } from 'lodash'
import { useSearchParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { searchParamsToObject } from '../../utils/helpers'

interface MCollapsableTableProps<T, K> {
  columns: Column<T>[]
  collapseColumns: Column<K>[]
  data: T[]
  isLoading: boolean
  customDataFilter?: (items: T[]) => T[]
  style?: CSSProperties
}

const Cell = styled(TableCell)(() => ({
  padding: '0.25rem 1rem',
}))

const MCollapsableTable = <T, K>({
  columns,
  collapseColumns,
  isLoading,
  data,
  customDataFilter,
  style,
}: MCollapsableTableProps<T, K>) => {
  const rowsPerPage = 10
  const [page, setPage] = useState<number>(0)
  const [currentOpenRow, setCurrentOpenRow] = useState<number | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    page: number,
  ) => {
    setPage(page)
    setSearchParams({
      ...searchParamsToObject(searchParams),
      page: String(page),
    })
  }

  const handleOpenRow = (recordIndex: number) => {
    setCurrentOpenRow((prevState) => {
      setSearchParams({
        ...searchParamsToObject(searchParams),
        row: prevState === recordIndex ? '' : String(recordIndex),
      })

      return prevState === recordIndex ? null : recordIndex
    })
  }

  useEffect(() => {
    if (data.length) {
      setPage(parseInt(searchParams.get('page') || '0'))
    }
  }, [searchParams, data])

  useEffect(() => {
    const row = searchParams.get('row')
    setCurrentOpenRow(row?.length ? +row : null)
  }, [])

  return (
    <>
      <TableContainer style={style} component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell />
              {map(columns, (column, index) => (
                <TableCell
                  width={column.width}
                  key={index}
                  align={column.align}
                >
                  {column.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {!isLoading && (
            <TableBody>
              {map(
                slice(
                  customDataFilter ? customDataFilter(data) : data,
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                ),
                (record, recordIndex) => (
                  <React.Fragment key={recordIndex}>
                    <TableRow
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      style={{ height: '50px' }}
                    >
                      <Cell
                        style={{ width: '34px', padding: '0.25rem 0.5rem' }}
                      >
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleOpenRow(recordIndex)}
                        >
                          {currentOpenRow === recordIndex ? (
                            <KeyboardArrowUpIcon />
                          ) : (
                            <KeyboardArrowDownIcon />
                          )}
                        </IconButton>
                      </Cell>
                      {map(columns, (column, columnIndex) => (
                        <Cell
                          component={column.component}
                          scope={column.scope}
                          key={columnIndex}
                        >
                          {column.onRender
                            ? column.onRender(record, recordIndex)
                            : String(record[column.key])}
                        </Cell>
                      ))}
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={6}
                      >
                        <Collapse
                          in={currentOpenRow === recordIndex}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                              <TableHead>
                                <TableRow>
                                  {map(collapseColumns, (column, index) => (
                                    <TableCell
                                      width={column.width}
                                      key={index}
                                      align={column.align}
                                    >
                                      {column.name}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {map(
                                  // @ts-expect-error test
                                  record.orders,
                                  (order, orderIndex: number) => (
                                    <TableRow
                                      key={orderIndex}
                                      sx={{
                                        '&:last-child td, &:last-child th': {
                                          border: 0,
                                        },
                                      }}
                                      style={{ height: '50px' }}
                                    >
                                      {map(
                                        collapseColumns,
                                        (
                                          collapseColumn,
                                          collapseColumnIndex,
                                        ) => (
                                          <Cell
                                            component={collapseColumn.component}
                                            scope={collapseColumn.scope}
                                            key={collapseColumnIndex}
                                          >
                                            {collapseColumn.onRender
                                              ? collapseColumn.onRender(
                                                  order,
                                                  orderIndex,
                                                )
                                              : String(
                                                  order[collapseColumn.key],
                                                )}
                                          </Cell>
                                        ),
                                      )}
                                    </TableRow>
                                  ),
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ),
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[rowsPerPage]}
        component="div"
        count={customDataFilter ? customDataFilter(data).length : data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
      />
    </>
  )
}

export default MCollapsableTable
