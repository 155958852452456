import React, { useEffect, useState } from 'react'
import { getServerUrl } from '../utils/helpers'
import { Fade, Grid2, Icon, Modal, styled, Typography } from '@mui/material'
import { Document, Page, pdfjs } from 'react-pdf'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

const DocumentPreviewModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const DocumentPreviewFade = styled(Fade)(() => ({
  outline: 'none',
}))

const PdfDocument = styled(Document)(() => ({
  height: 'calc(90vh)',
  width: 'fit-content !important',
}))

const PdfPage = styled(Page)(() => ({
  height: 'calc(90vh)',
  width: 'fit-content !important',
  '& canvas': {
    height: 'calc(90vh) !important',
    width: 'fit-content !important',
  },
}))

interface PdfPreviewProps {
  open: boolean
  url: string | null
  handleClose: () => void
}

export default function PdfPreview({
  open,
  url,
  handleClose,
}: PdfPreviewProps) {
  const [pagesNumber, setPagesNumber] = useState(1)
  const [currentPageNumber, setCurrentPageNumber] = useState(1)

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void => {
    setPagesNumber(numPages)
  }

  useEffect(() => {
    setPagesNumber(1)
  }, [url])

  return (
    <DocumentPreviewModal open={open} onClose={handleClose}>
      <DocumentPreviewFade in={open} timeout={500}>
        <div>
          {url && (
            <PdfDocument
              file={getServerUrl(url)}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <PdfPage pageNumber={currentPageNumber} scale={1.0}>
                <Grid2 container justifyContent="center">
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      zIndex: 9999,
                      backgroundColor: '#ffffff',
                      paddingBottom: '1rem',
                    }}
                  >
                    <Icon
                      style={
                        currentPageNumber === 1 ? { visibility: 'hidden' } : {}
                      }
                      onClick={() => setCurrentPageNumber((num) => num - 1)}
                    >
                      <ChevronLeftIcon style={{ color: '#000000' }} />
                    </Icon>
                    <div style={{ margin: '0 1rem' }}>
                      <Typography align="center">
                        Strona {currentPageNumber} z {pagesNumber}
                      </Typography>
                    </div>
                    <Icon
                      style={
                        currentPageNumber === pagesNumber
                          ? { visibility: 'hidden' }
                          : {}
                      }
                      onClick={() => setCurrentPageNumber((num) => num + 1)}
                    >
                      <ChevronRightIcon style={{ color: '#000000' }} />
                    </Icon>
                  </div>
                </Grid2>
              </PdfPage>
            </PdfDocument>
          )}
        </div>
      </DocumentPreviewFade>
    </DocumentPreviewModal>
  )
}
