import React from 'react'
import { Grid2 } from '@mui/material'
import MTable from '../material/MTable'
import { Column } from '../../types/customTableTypes'
import { Grn, GrnOrder } from '../../types/order'
import { Icon } from '../../styles/styles'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import useImagesStore from '../../store/useImagesStore'
import useSketchesStore from '../../store/useSketchesStore'
import {
  translateFinishesValue,
  translateGlossLevelValue,
  translatePaintTypeValue,
  translateQuantityTypeValue,
  translateSandblasting,
} from '../../utils/helpers'

interface ExtendGrnOrder extends Partial<GrnOrder> {
  actions?: string
}

interface OrdersListProps {
  setOrderIndex: (orderIndex: number) => void
  methods: UseFormReturn<Grn>
}

export default function OrdersList({
  methods,
  setOrderIndex,
}: OrdersListProps) {
  const { control, watch } = methods

  const { remove } = useFieldArray({
    control,
    name: 'orders',
    keyName: 'formOrderId',
  })

  const orders = watch('orders')

  const { removeImagesContainer } = useImagesStore()
  const { removeSketchesContainer } = useSketchesStore()

  const columns: Column<ExtendGrnOrder>[] = [
    {
      key: 'quantity',
      name: 'Ilość',
      align: 'left',
    },
    {
      key: 'quantityType',
      name: 'Typ',
      align: 'left',
      onRender: ({ quantityType }) => (
        <span>{translateQuantityTypeValue(quantityType)}</span>
      ),
    },
    {
      key: 'product',
      name: 'Nazwa towaru',
      align: 'left',
    },
    {
      key: 'ralColor',
      name: 'Kolor RAL',
      align: 'left',
    },
    {
      key: 'glossLevel',
      name: 'Stopień połysku',
      align: 'left',
      onRender: ({ glossLevel }) => (
        <span>{translateGlossLevelValue(glossLevel)}</span>
      ),
    },
    {
      key: 'finishing',
      name: 'Wykończenie',
      align: 'left',
      onRender: ({ finishing }) => (
        <span>{translateFinishesValue(finishing)}</span>
      ),
    },
    {
      key: 'paintType',
      name: 'Rodzaj farby',
      align: 'left',
      onRender: ({ paintType }) => (
        <span>{translatePaintTypeValue(paintType)}</span>
      ),
    },
    {
      key: 'base',
      name: 'Podkład',
      align: 'left',
      onRender: ({ base }) => <span>{base === 'yes' ? 'Tak' : 'Nie'}</span>,
    },
    {
      key: 'sandblasting',
      name: 'Piaskowanie',
      align: 'left',
      onRender: ({ sandblasting }) => (
        <span>{translateSandblasting(sandblasting)}</span>
      ),
    },
    {
      key: 'grinding',
      name: 'Szlifowanie',
      align: 'left',
      onRender: ({ grinding }) => (
        <span>{grinding === 'yes' ? 'Tak' : 'Nie'}</span>
      ),
    },
    {
      key: 'actions',
      name: 'Actions',
      width: '128px',
      align: 'right',
      onRender: (_item, index) => (
        <Grid2 container direction="row" justifyContent="flex-end">
          <Icon onClick={() => setOrderIndex(index)}>
            <EditIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon
            onClick={() => {
              removeImagesContainer(index)
              removeSketchesContainer(index)
              remove(index)
            }}
          >
            <DeleteIcon style={{ color: '#000000' }} />
          </Icon>
        </Grid2>
      ),
    },
  ]

  return (
    <Grid2 container direction="row" gap={4}>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <MTable columns={columns} data={orders} isLoading={false} />
      </Grid2>
    </Grid2>
  )
}
