import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import {
  filter,
  find,
  findIndex,
  flatMap,
  forEach,
  isNull,
  map,
  slice,
  some,
  toLower,
  startsWith,
  orderBy,
  isString,
  pick,
  includes,
} from 'lodash'
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  rectIntersection,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { Grid2 } from '@mui/material'
import DnDColumn from './DnDColumn'
import {
  ProductionBoardColumn,
  ProductionBoardRecord,
} from '../../types/production'
import useProductionStore from '../../store/useProductionStore'
import { canDrag } from '../../utils/dndHelpers'
import MDialog, { MDialogHandle } from '../material/MDialog'
import DndRejectForm from './DndRejectForm'
import DndCard from './DndCard'
import useAuthStore from '../../store/useAuthStore'
import DndSplitForm from './DndSplitForm'
import { useNotification } from '../../context/NotificationContext'

interface DnDBoardProps {
  items: ProductionBoardColumn[]
  type: 'painting' | 'sandblasting'
  search: string
  style: CSSProperties
}

const DnDBoard = ({ items, type, search, style }: DnDBoardProps) => {
  const { openNotification } = useNotification()
  const [columns, setColumns] = useState<ProductionBoardColumn[]>([])
  const [activeColumnId, setActiveColumnId] = useState<string | null>(null)
  const [activeItem, setActiveItem] = useState<ProductionBoardRecord | null>(
    null,
  )
  const [splitValue, setSplitValue] = useState<number>(1)
  const [newColumnId, setNewColumnId] = useState<string>('')

  const rejectDialogRef = useRef<MDialogHandle | null>(null)
  const splitDialogRef = useRef<MDialogHandle | null>(null)

  const { updateProductionStatus, splitOrderTicker, changeOrder } =
    useProductionStore()
  const { me } = useAuthStore()

  const filteredColumns = () => {
    const newColumns: ProductionBoardColumn[] = []
    forEach(columns, (column) => {
      const filteredItems = filter(column.items, (item) => {
        const searchLower = toLower(search)

        if (isString(item.ralColor)) {
          const colorLower = toLower(item.ralColor)

          if (startsWith(colorLower, searchLower)) {
            return true
          }

          if (colorLower.startsWith('ral')) {
            const colorDigits = colorLower.replace(/^ral\s*/, '')
            if (startsWith(colorDigits, searchLower)) {
              return true
            }
          }
        }

        return some(
          pick(item, ['product', 'name']),
          (value) => isString(value) && includes(toLower(value), searchLower),
        )
      })

      newColumns.push({
        ...column,
        items: filteredItems,
      })
    })

    return newColumns
  }

  useEffect(() => {
    setColumns(items)
  }, [items])

  const getSandblastingStatusLabel = (status: string) => {
    switch (status) {
      case 'new':
        return 'Zlecenia'
      case 'planning':
        return 'Plan Produkcji'
      case 'sandblasting':
        return 'W realizacji'
      case 'done':
        return 'Gotowe'
      case 'toInvoiced':
        return 'Do zafakturowania'
      case 'invoiced':
        return 'Zafakturowane'
      default:
        return ''
    }
  }

  const getPaintingStatusLabel = (status: string) => {
    switch (status) {
      case 'new':
        return 'Zlecenia'
      case 'planning':
        return 'Plan Produkcji'
      case 'painting':
        return 'W realizacji'
      case 'verify':
        return 'Gotowe'
      case 'rejected':
        return 'Odrzucone'
      case 'done':
        return 'Do zafakturowania'
      case 'invoiced':
        return 'Zafakturowane'
      default:
        return ''
    }
  }

  const findColumn = (unique: string | null) => {
    if (!unique) {
      return null
    }

    if (some(columns, (c) => c.id === unique)) {
      return find(columns, (c) => c.id === unique) ?? null
    }

    const id = String(unique)

    const itemWithColumnId = flatMap(columns, (c) => {
      const columnId = c.id
      return map(c.items, (i) => ({ itemId: i.id, columnId: columnId }))
    })

    const columnId = find(
      itemWithColumnId,
      (i) => String(i.itemId) === id,
    )?.columnId
    return find(columns, (c) => c.id === columnId) ?? null
  }

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    const activeId = String(active.id)

    const activeColumn = findColumn(activeId)

    if (!activeColumn) {
      return null
    }

    const activeItems = activeColumn.items

    const activeIndex = findIndex(
      activeItems,
      (item) => String(item.id) === activeId,
    )

    setActiveItem(activeItems[activeIndex])
    setActiveColumnId(activeColumn.id)

    return null
  }

  const handleDragOver = (event: DragOverEvent) => {
    const { active, over, delta } = event
    const activeId = String(active.id)
    const overId = over ? String(over.id) : null

    const originalColumn = findColumn(activeColumnId)
    const activeColumn = findColumn(activeId)
    const overColumn = findColumn(overId)

    if (
      !activeColumn ||
      !overColumn ||
      !originalColumn ||
      activeColumn === overColumn
    ) {
      return null
    }

    if (!canDrag(originalColumn.id, overColumn.id, me!.role)) {
      return null
    }

    const activeItems = activeColumn.items
    const overItems = overColumn.items

    const activeIndex = findIndex(
      activeItems,
      (item) => String(item.id) === activeId,
    )
    const overIndex = findIndex(overItems, (item) => String(item.id) === overId)

    setColumns((prevState) => {
      const newIndex = () => {
        const putOnBelowLastItem =
          overIndex === overItems.length - 1 && delta.y > 0
        const modifier = putOnBelowLastItem ? 1 : 0
        return overIndex >= 0 ? overIndex + modifier : overItems.length + 1
      }

      return map(prevState, (column) => {
        if (column.id === activeColumn.id) {
          return {
            ...column,
            items: filter(activeItems, (item) => String(item.id) !== activeId),
          }
        } else if (column.id === overColumn.id) {
          if (type === 'painting') {
            activeItems[activeIndex].paintingStatus = overColumn.id
          } else {
            activeItems[activeIndex].sandblastingStatus = overColumn.id
          }

          const updatedItems = [
            ...slice(overItems, 0, newIndex()),
            activeItems[activeIndex],
            ...slice(overItems, newIndex(), overItems.length),
          ]

          return {
            ...column,
            items: updatedItems,
          }
        } else {
          return column
        }
      })
    })

    return null
  }

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event
    const activeId = String(active.id)
    const overId = over ? String(over.id) : null

    const originalColumn = findColumn(activeColumnId)
    const activeColumn = findColumn(activeId)
    const overColumn = findColumn(overId)

    if (!activeColumn || !overColumn || !originalColumn) {
      return null
    }

    if (!canDrag(originalColumn.id, overColumn.id, me!.role)) {
      return null
    }

    setNewColumnId(overColumn.id)

    const activeItems = activeColumn.items
    const overItems = overColumn.items

    const activeIndex = findIndex(
      activeItems,
      (item) => String(item.id) === activeId,
    )

    const overIndex = findIndex(overItems, (item) => String(item.id) === overId)

    if (originalColumn.id !== overColumn.id) {
      if (overColumn.id === 'rejected') {
        rejectDialogRef.current?.changeDialogVisibility(true)
      } else if (overColumn.id === 'done') {
        openNotification('Zlecenie zakończone', 'success')
        await updateProductionStatus(type, activeItems[activeIndex])
      } else if (overColumn.id === 'painting' || overColumn.id === 'invoiced') {
        const allocatedQuantity = activeItems[activeIndex].allocatedQuantity
        const quantity = allocatedQuantity ?? activeItems[activeIndex].quantity
        if (quantity > 1) {
          splitDialogRef.current?.changeDialogVisibility(true)
          return
        } else {
          await updateProductionStatus(type, activeItems[activeIndex])
        }
      } else {
        await updateProductionStatus(type, activeItems[activeIndex])
      }
    }

    if (activeColumn.id !== overColumn.id) {
      const movedItem = activeItems[activeIndex]

      if (type === 'painting') {
        movedItem.paintingStatus = overColumn.id
      } else {
        movedItem.sandblastingStatus = overColumn.id
      }

      setColumns((prevState) => {
        return map(prevState, (column) => {
          if (column.id === activeColumn.id) {
            return {
              ...column,
              items: filter(column.items, (item) => item.id !== movedItem.id),
            }
          } else if (column.id === overColumn.id) {
            return {
              ...column,
              items: [
                ...column.items,
                {
                  ...movedItem,
                  orderId: activeColumn.items[activeIndex].orderId,
                },
              ],
            }
          }
          return column
        })
      })
    }

    if (activeColumn.id === overColumn.id) {
      const movedItems = arrayMove(activeColumn.items, activeIndex, overIndex)

      const newItems = movedItems.map((item, index) => ({
        ...item,
        ...(type === 'painting' && { paintingOrder: index + 1 }),
        ...(type === 'sandblasting' && { sandblastingOrder: index + 1 }),
      }))

      setColumns((prevState) => {
        return map(prevState, (column) => {
          if (column.id === activeColumn.id) {
            return {
              ...column,
              items: newItems,
            }
          }

          return column
        })
      })

      await changeOrder(type, newItems)
    }

    return null
  }

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={rectIntersection}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        onDragStart={handleDragStart}
      >
        <Grid2
          container
          direction="row"
          gap={2}
          style={{
            ...style,
            overflowX: 'auto',
            flexWrap: 'nowrap',
            width: '100%',
            minWidth: '100%',
          }}
        >
          {filteredColumns().map((column) => (
            <DnDColumn
              key={column.id}
              id={column.id}
              label={
                type === 'sandblasting'
                  ? getSandblastingStatusLabel(column.id)
                  : getPaintingStatusLabel(column.id)
              }
              items={orderBy(column.items, [
                type === 'painting' ? 'paintingOrder' : 'sandblastingOrder',
              ])}
              type={type}
            ></DnDColumn>
          ))}
        </Grid2>
        <DragOverlay>
          {activeItem ? <DndCard type={type} item={activeItem} /> : null}
        </DragOverlay>
      </DndContext>
      <MDialog
        ref={splitDialogRef}
        confirmLabel="Zapisz"
        content={
          <DndSplitForm activeItem={activeItem} setSplitValue={setSplitValue} />
        }
        onConfirm={async () => {
          if (activeItem) {
            if (Number(activeItem.allocatedQuantity) !== activeItem.quantity) {
              await splitOrderTicker({
                orderId: activeItem.orderId,
                separatedFrom: activeItem.id,
                allocatedQuantity: splitValue,
                totalQuantity:
                  activeItem?.allocatedQuantity || activeItem.quantity,
                newColumnId,
                productionType: type,
              })
            } else {
              await updateProductionStatus(type, activeItem)
            }
            setNewColumnId('')
            setSplitValue(1)
            setActiveItem(null)
          }
        }}
        cancelLabel="Pomiń"
        onCancel={async () => {
          if (activeItem) {
            await updateProductionStatus(type, activeItem)
          }
        }}
        title="Podziel zlecenie"
      />
      <MDialog
        ref={rejectDialogRef}
        title="Zlecenie odrzucone"
        content={
          <DndRejectForm
            activeItem={activeItem}
            setActiveItem={setActiveItem}
          />
        }
        onConfirm={async () => {
          if (activeItem) {
            await updateProductionStatus(type, activeItem)
          }
        }}
        confirmLabel="Dodaj komentarz"
        disableConfirm={
          isNull(activeItem?.rejectedMessage) ||
          activeItem?.rejectedMessage.length === 0
        }
      />
    </>
  )
}

export default DnDBoard
