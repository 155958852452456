import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useOrdersStore from '../../store/useOrdersStore'
import {
  Fade,
  Grid2,
  Icon,
  IconButton,
  Modal,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import PrintIcon from '@mui/icons-material/Print'
import {
  getServerUrl,
  translateFinishesValue,
  translateGlossLevelValue,
  translatePaintTypeValue,
  translateQuantityTypeValue,
  translateSandblasting,
} from '../../utils/helpers'
import { find, isNull, map } from 'lodash'
import dayjs from 'dayjs'
import useImagesStore from '../../store/useImagesStore'
import useSketchesStore from '../../store/useSketchesStore'
import { Container, HeaderContainer } from '../../styles/styles'
import { OrderImage } from '../../types/image'

const ContentContainer = styled(TableContainer)(() => ({
  height: 'calc(100dvh - 88px)',
  overflowY: 'auto',
  overflowX: 'hidden',
}))

const TableCellName = styled(TableCell)(() => ({
  width: '30%',
}))

const TableCellContent = styled(TableCell)(() => ({
  width: '70%',
}))

const ImageModal = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ImageFade = styled(Fade)(() => ({
  outline: 'none',
}))

const ImagesContainer = styled('div')(() => ({
  display: 'flex',
  overflowX: 'auto',
  whiteSpace: 'nowrap',
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  height: '200px',
  alignItems: 'center',
}))

const ImageContainer = styled('div')(() => ({
  position: 'relative',
}))

const AbsolutePrintIcon = styled(Icon)(() => ({
  position: 'absolute',
  top: '0.5rem',
  right: '1rem',
  backgroundColor: '#0d539e',
  borderRadius: '25px',
  padding: '0.15rem',
  cursor: 'pointer',
}))

const OrderDetailPage = () => {
  const { orderId, ciNumber } = useParams()
  const navigate = useNavigate()
  const { orders, getOrders } = useOrdersStore()
  const { printImage, getImagesByOrderId } = useImagesStore()
  const { printSketch, getSketchesByOrderId } = useSketchesStore()
  const token = localStorage.getItem('token')
  const [images, setImages] = useState<OrderImage[]>([])
  const [sketches, setSketches] = useState<OrderImage[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [image, setImage] = useState<string>('')

  const handleClose = () => {
    setOpen(false)
  }

  const handleImage = (value: string) => {
    setImage(value)
    setOpen(true)
  }

  const handleFetchDetails = async () => {
    await getOrders()
    if (ciNumber && orderId) {
      await getImagesByOrderId(encodeURIComponent(ciNumber), +orderId).then(
        (images) => setImages(images),
      )
      await getSketchesByOrderId(encodeURIComponent(ciNumber), +orderId).then(
        (images) => setSketches(images),
      )
    }
  }

  useEffect(() => {
    handleFetchDetails()
  }, [])

  const grnDetails = find(
    orders,
    (grn) => grn.ciNumber === decodeURIComponent(ciNumber as string),
  )

  const orderDetails = find(
    grnDetails?.orders,
    (order) => order.id === Number(orderId),
  )

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid2
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <IconButton
            onClick={() => {
              if (isNull(token)) {
                navigate('/login')
              } else {
                navigate(-1)
              }
            }}
          >
            <ArrowBackIcon style={{ color: '#000000' }} />
          </IconButton>
          <Typography variant="h5">PZ: {orderDetails?.ciNumber}</Typography>
        </Grid2>
        <Grid2
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="h5">Zlecenie nr.: {orderDetails?.id}</Typography>
        </Grid2>
      </HeaderContainer>
      <ContentContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCellName variant="head">
                Imię Nazwisko / Nazwa firmy
              </TableCellName>
              <TableCellContent>{grnDetails?.name}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">NIP</TableCellName>
              <TableCellContent>{grnDetails?.taxId}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Adres</TableCellName>
              <TableCellContent>
                {grnDetails?.postalCode} {grnDetails?.city} {grnDetails?.road}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Telefon kontaktowy</TableCellName>
              <TableCellContent>{grnDetails?.phoneNumber}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Nazwa towaru</TableCellName>
              <TableCellContent>{orderDetails?.product}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Ilość</TableCellName>
              <TableCellContent>
                {orderDetails?.quantity}{' '}
                {translateQuantityTypeValue(orderDetails?.quantityType)}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">RAL</TableCellName>
              <TableCellContent>{orderDetails?.ralColor}</TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Podkład</TableCellName>
              <TableCellContent>
                {orderDetails?.base === 'yes' ? 'Tak' : 'Nie'}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Piaskowanie</TableCellName>
              <TableCellContent>
                {translateSandblasting(orderDetails?.sandblasting)}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Szlifowanie</TableCellName>
              <TableCellContent>
                {orderDetails?.grinding === 'yes' ? 'Tak' : 'Nie'}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Stopień połysku</TableCellName>
              <TableCellContent>
                {translateGlossLevelValue(orderDetails?.glossLevel)}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Wykończenie</TableCellName>
              <TableCellContent>
                {translateFinishesValue(orderDetails?.finishing)}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Rodzaj farby</TableCellName>
              <TableCellContent>
                {translatePaintTypeValue(orderDetails?.paintType)}
              </TableCellContent>
            </TableRow>
            <TableRow>
              <TableCellName variant="head">Data przyjęcia</TableCellName>
              <TableCellContent>
                {dayjs(grnDetails?.date).format('YYYY-MM-DD HH:ss')}
              </TableCellContent>
            </TableRow>
            {orderDetails?.description && (
              <TableRow>
                <TableCellName variant="head">Opis / Uwagi</TableCellName>
                <TableCellContent>{orderDetails?.description}</TableCellContent>
              </TableRow>
            )}
            <TableRow style={{ height: '200px' }}>
              <TableCellName variant="head">Podpis zlecającego</TableCellName>
              <TableCellContent>
                {grnDetails?.signature && (
                  <img
                    src={getServerUrl(grnDetails.signature)}
                    alt="signature"
                  />
                )}
              </TableCellContent>
            </TableRow>
            {images.length > 0 && (
              <TableRow style={{ height: '200px' }}>
                <TableCellName variant="head">Załączone zdjęcia</TableCellName>
                <TableCell
                  style={{ display: 'grid', width: '100% !important' }}
                >
                  <ImagesContainer>
                    {images.length > 0 &&
                      map(images, (image) => (
                        <ImageContainer>
                          <AbsolutePrintIcon
                            onClick={(e) => {
                              e.stopPropagation()
                              printImage(image.id)
                            }}
                          >
                            <PrintIcon
                              style={{
                                color: '#ffffff',
                              }}
                            />
                          </AbsolutePrintIcon>
                          <img
                            onClick={() => handleImage(image.path)}
                            key={image.id}
                            style={{
                              height: '167px',
                              width: 'auto',
                              marginRight: '0.5rem',
                              flexShrink: 0,
                            }}
                            src={getServerUrl(image.path)}
                            alt={String(image.id)}
                          />
                        </ImageContainer>
                      ))}
                  </ImagesContainer>
                </TableCell>
              </TableRow>
            )}
            {sketches.length > 0 && (
              <TableRow style={{ height: '200px' }}>
                <TableCellName variant="head">
                  Załączone rysunki techniczne
                </TableCellName>
                <TableCell
                  style={{ display: 'grid', width: '100% !important' }}
                >
                  <ImagesContainer>
                    {sketches.length > 0 &&
                      map(sketches, (sketch) => (
                        <ImageContainer>
                          <AbsolutePrintIcon
                            onClick={(e) => {
                              e.stopPropagation()
                              printSketch(sketch.id)
                            }}
                          >
                            <PrintIcon
                              style={{
                                color: '#ffffff',
                              }}
                            />
                          </AbsolutePrintIcon>
                          <img
                            onClick={() => handleImage(sketch.path)}
                            key={sketch.id}
                            style={{
                              height: '167px',
                              width: 'auto',
                              marginRight: '0.5rem',
                              flexShrink: 0,
                            }}
                            src={getServerUrl(sketch.path)}
                            alt={String(sketch.id)}
                          />
                        </ImageContainer>
                      ))}
                  </ImagesContainer>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <ImageModal open={open} onClose={handleClose}>
          <ImageFade in={open} timeout={500}>
            <img
              src={getServerUrl(image)}
              alt="asd"
              style={{ maxHeight: '90%', maxWidth: '90%' }}
            />
          </ImageFade>
        </ImageModal>
      </ContentContainer>
    </Container>
  )
}

export default OrderDetailPage
