import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { NotificationService } from '../service/NotificationService'

interface PrinterStatuses {
  paperStatusMessage: boolean
  printerStatusMessage: boolean
  ribbonStatusMessage: boolean
}

interface Store {
  printLabel: (data: object) => Promise<void>
  getPrinterStatus: () => Promise<void>
  printerStatuses: PrinterStatuses | null
}

const usePrinterStore = create<Store>((set) => ({
  printerStatuses: null,

  printLabel: async (data) => {
    await callApi<{ data: { message: string } }>(
      '/api/print-label',
      'POST',
      data,
    )
      .then((res) => {
        NotificationService.showNotification(res.data.message, 'success')
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
      })
  },

  getPrinterStatus: async () => {
    await callApi<{ data: PrinterStatuses }>(`/api/printer-status`, 'GET')
      .then((res) => {
        set(() => ({ printerStatuses: res.data }))
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
      })
  },
}))

export default usePrinterStore
