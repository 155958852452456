import React, { useEffect, useState } from 'react'
import useAuthStore from '../store/useAuthStore'
import { isNull } from 'lodash'
import { Navigate } from 'react-router-dom'
import useSettingsStore from '../store/useSettigsStore'

interface ProtectedRouteProps {
  children: React.ReactNode
}

const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { me, getMyself } = useAuthStore()
  const { getSettings } = useSettingsStore()
  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    const checkIsLoggedIn = async () => {
      const res = await getMyself()

      if (!res) {
        localStorage.removeItem('token')
      } else if (res.exp * 1000 < Date.now()) {
        localStorage.removeItem('token')
      } else {
        await getSettings()
      }

      setIsChecking(false)
    }

    checkIsLoggedIn()
  }, [])

  if (!isChecking && isNull(me)) {
    return <Navigate to="/login" replace />
  }

  return children
}

export default ProtectedRoute
