import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import useOrdersStore from '../../store/useOrdersStore'
import { Grid2 } from '@mui/material'
import PrintIcon from '@mui/icons-material/Print'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteIcon from '@mui/icons-material/Delete'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner'
import MButton from '../../components/material/MButton'
import { filter, includes, isString, pick, some, toLower } from 'lodash'
import { useNavigate, useSearchParams } from 'react-router-dom'
import dayjs from 'dayjs'
import ConfirmPrintLabelDialog from '../../components/ConfirmPrintLabelDialog'
import { Grn, GrnOrder } from '../../types/order'
import usePrinterStore from '../../store/usePrinterStore'
import MSearchField from '../../components/material/MSearchField'
import { Container, HeaderContainer, Icon } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import useSocket from '../../hooks/useSocket'
import MDialog, { MDialogHandle } from '../../components/material/MDialog'
import { Column } from '../../types/customTableTypes'
import MCollapsableTable from '../../components/material/MCollapsableTable'
import {
  searchParamsToObject,
  translateQuantityTypeValue,
  translateSandblasting,
} from '../../utils/helpers'
import useDebouncedFunction from '../../hooks/useDebouncedFunction'
import useAppStore from '../../store/useAppStore'

interface OrderDialogData {
  ciNumber: string
  orderId?: number
}

interface ExtendGrn extends Grn {
  actions?: string
}

interface ExtendGrnOrder extends GrnOrder {
  actions?: string
}

const OrdersPage = () => {
  const [search, setSearch] = useState<string>('')
  const [openConfirmPrintDialog, setOpenConfirmPrintDialog] =
    useState<boolean>(false)
  const [toDelete, setToDelete] = useState<OrderDialogData | null>(null)
  const [toPrint, setToPrint] = useState<OrderDialogData | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [searchParams, setSearchParams] = useSearchParams()

  const dialogRef = useRef<MDialogHandle>(null)

  const navigate = useNavigate()

  const { setIsOpen } = useAppStore()
  const { printerStatuses } = usePrinterStore()
  const { orders, getOrders, deleteOrder, deleteAllOrdersByCiNumber } =
    useOrdersStore()

  const filteredOrders = (items: Grn[]) => {
    return filter(items, (item) => {
      const mainFieldsMatch = some(
        pick(item, ['name', 'ciNumber']),
        (value) => isString(value) && includes(toLower(value), toLower(search)),
      )

      const ordersMatch = some(item.orders, (order) => {
        return some(
          pick(order, ['product', 'ralColor']),
          (value) =>
            isString(value) && includes(toLower(value), toLower(search)),
        )
      })

      return mainFieldsMatch || ordersMatch
    })
  }

  const handleOpenDialog = (order: ExtendGrnOrder | null) => {
    if (order) {
      setToDelete({
        orderId: order.id,
        ciNumber: order.ciNumber,
      })
      dialogRef.current?.changeDialogVisibility(true)
    }
  }

  const handleDeleteGrn = (grn: ExtendGrn | null) => {
    if (grn) {
      setToDelete({
        ciNumber: grn.ciNumber,
      })
      dialogRef.current?.changeDialogVisibility(true)
    }
  }

  const confirmPrintDialogControl = (
    order: ExtendGrnOrder | null,
    isOpen: boolean,
  ) => {
    if (order) {
      setToPrint({
        orderId: order.id,
        ciNumber: order.ciNumber,
      })
    }
    setOpenConfirmPrintDialog(isOpen)
  }

  const isPrinterConnected = () => {
    return (
      !printerStatuses?.printerStatusMessage ||
      !printerStatuses?.paperStatusMessage ||
      !printerStatuses?.ribbonStatusMessage
    )
  }

  const debouncedUpdateSearchParams = useDebouncedFunction(
    (value: string) =>
      setSearchParams({
        ...searchParamsToObject(searchParams),
        search: value,
      }),
    1000,
  )

  const handleSearch = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const value = event.target.value
    setSearch(value)
    debouncedUpdateSearchParams(value)
  }

  const collapseColumns: Column<ExtendGrnOrder>[] = [
    {
      key: 'product',
      name: 'Nazwa towaru',
      align: 'left',
    },
    {
      key: 'quantity',
      name: 'Ilość',
      align: 'left',
      onRender: ({ quantity, quantityType }) => (
        <span>
          {quantity} {translateQuantityTypeValue(quantityType)}
        </span>
      ),
    },
    {
      key: 'ralColor',
      name: 'RAL',
      align: 'left',
    },
    {
      key: 'base',
      name: 'Podkład',
      align: 'left',
      onRender: ({ base }) => <span>{base === 'yes' ? 'Tak' : 'Nie'}</span>,
    },
    {
      key: 'sandblasting',
      name: 'Piaskowanie',
      align: 'left',
      onRender: ({ sandblasting }) => (
        <span>{translateSandblasting(sandblasting)}</span>
      ),
    },
    {
      key: 'grinding',
      name: 'Szlifowanie',
      align: 'left',
      onRender: ({ grinding }) => (
        <span>{grinding === 'yes' ? 'Tak' : 'Nie'}</span>
      ),
    },
    {
      key: 'actions',
      name: 'Actions',
      width: '128px',
      align: 'right',
      onRender: (item) => (
        <Grid2 container direction="row" justifyContent="flex-end">
          {!isPrinterConnected() && (
            <Icon
              onClick={() => {
                confirmPrintDialogControl(item, true)
              }}
            >
              <PrintIcon
                style={{
                  color: '#000000',
                }}
              />
            </Icon>
          )}
          <Icon
            onClick={() =>
              navigate(
                `/orders/${encodeURIComponent(item.ciNumber)}/${item.id}`,
              )
            }
          >
            <VisibilityIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => handleOpenDialog(item)}>
            <DeleteIcon style={{ color: '#000000' }} />
          </Icon>
        </Grid2>
      ),
    },
  ]

  const columns: Column<ExtendGrn>[] = [
    {
      key: 'ciNumber',
      name: 'Numer PZ',
      align: 'left',
    },
    {
      key: 'name',
      name: 'Kontrahent',
      width: '30%',
      align: 'left',
    },
    {
      key: 'phoneNumber',
      name: 'Tel.',
      align: 'left',
    },
    {
      key: 'date',
      name: 'Data przyjęcia',
      align: 'left',
      onRender: ({ date }) => (
        <span>{dayjs(date).format('YYYY-MM-DD HH:ss')}</span>
      ),
    },
    {
      key: 'actions',
      name: 'Actions',
      width: '128px',
      align: 'right',
      onRender: (item) => (
        <Grid2 container direction="row" justifyContent="flex-end">
          <Icon
            onClick={() =>
              navigate(`/orders/${encodeURIComponent(item.ciNumber)}`)
            }
          >
            <VisibilityIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon
            onClick={() => {
              setIsOpen(false)
              navigate(`/orders/edit/${encodeURIComponent(item.ciNumber)}`)
            }}
          >
            <EditIcon style={{ color: '#000000' }} />
          </Icon>
          <Icon onClick={() => handleDeleteGrn(item)}>
            <DeleteIcon style={{ color: '#000000' }} />
          </Icon>
        </Grid2>
      ),
    },
  ]

  useEffect(() => {
    if (!orders.length) {
      setIsLoading(true)
    }
    getOrders().finally(() => {
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    setSearch(searchParams.get('search') || '')
  }, [searchParams])

  useEffect(() => {
    return () => {
      debouncedUpdateSearchParams.cancel()
    }
  }, [])

  useSocket('order_created', () => getOrders())
  useSocket('order_updated', () => getOrders())
  useSocket('order_deleted', () => getOrders())

  return (
    <Container>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <MainHeader title="Zlecenia" />
        <Grid2 container direction="row" gap={4}>
          <MButton
            style={{ width: '50px' }}
            variant="contained"
            onClick={() => navigate('scan-qr')}
          >
            <QrCodeScannerIcon />
          </MButton>
          <MButton
            style={{ width: 'fit-content' }}
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              setIsOpen(false)
              navigate('orders/add')
            }}
          >
            Utwórz zlecenie
          </MButton>
        </Grid2>
      </HeaderContainer>
      <MSearchField
        sx={{ '&: div': { backgroundColor: 'transparent' } }}
        variant="standard"
        placeholder="Wyszukaj..."
        value={search}
        onChange={handleSearch}
      />
      <MCollapsableTable
        columns={columns}
        collapseColumns={collapseColumns}
        data={orders}
        isLoading={isLoading}
        customDataFilter={filteredOrders}
        style={{ maxHeight: 'calc(100dvh - 184px)', overflow: 'auto' }}
      />
      <ConfirmPrintLabelDialog
        open={openConfirmPrintDialog}
        closeDialog={() => confirmPrintDialogControl(null, false)}
        ciNumber={toPrint?.ciNumber}
        orderId={toPrint?.orderId}
      />
      <MDialog
        ref={dialogRef}
        title="Usuwanie zlecenia"
        content="Czy na pewno chcesz usunąć to zlecenie?"
        onConfirm={() => {
          if (toDelete) {
            if (toDelete.orderId) {
              deleteOrder(toDelete.ciNumber, toDelete.orderId)
            } else {
              deleteAllOrdersByCiNumber(toDelete.ciNumber)
            }
          }
          setToDelete(null)
        }}
        onCancel={() => setToDelete(null)}
        cancelLabel="Anuluj"
        confirmLabel="Usuń"
      />
    </Container>
  )
}

export default OrdersPage
