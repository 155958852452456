import {
  AppBar,
  Box,
  Button,
  Dialog,
  Grid2,
  IconButton,
  Slide,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, {
  ReactElement,
  Ref,
  forwardRef,
  useState,
  useImperativeHandle,
} from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Grn } from '../../types/order'
import AdditionalInformationTab from './AdditionalInformationTab'
import OrderDetailsTab from './OrderDetailsTab'
import { UseFormReturn } from 'react-hook-form'
import { isNumber } from 'lodash'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

interface OrderFormModalProps {
  methods: UseFormReturn<Grn>
  orderIndex: number
}

export interface OrderFormModalHandle {
  handleOpenModal: (open: boolean) => void
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement<unknown>
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const CustomTabPanel = ({ children, index, value }: TabPanelProps) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

const OrderFormModal = forwardRef<OrderFormModalHandle, OrderFormModalProps>(
  ({ methods, orderIndex }, ref) => {
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<number>(0)

    const isRecordValid = () => {
      const [
        product,
        quantity,
        quantityType,
        ralColor,
        glossLevel,
        finishing,
        paintType,
        sandblasting,
        base,
        grinding,
      ] = methods.watch([
        `orders.${orderIndex}.product`,
        `orders.${orderIndex}.quantity`,
        `orders.${orderIndex}.quantityType`,
        `orders.${orderIndex}.ralColor`,
        `orders.${orderIndex}.glossLevel`,
        `orders.${orderIndex}.finishing`,
        `orders.${orderIndex}.paintType`,
        `orders.${orderIndex}.sandblasting`,
        `orders.${orderIndex}.base`,
        `orders.${orderIndex}.grinding`,
      ])

      return (
        product?.length > 0 &&
        quantityType?.length > 0 &&
        isNumber(Number(quantity)) &&
        sandblasting?.length > 0 &&
        (sandblasting !== 'only'
          ? base?.length > 0 &&
            grinding?.length > 0 &&
            paintType?.length > 0 &&
            ralColor?.length > 0 &&
            glossLevel?.length > 0 &&
            finishing?.length > 0
          : true)
      )
    }

    const handleOpenModal = (open: boolean) => setOpenModal(open)

    const handleChange = (_event: React.SyntheticEvent, newValue: number) =>
      setActiveTab(newValue)

    useImperativeHandle(ref, () => ({
      handleOpenModal,
    }))

    return (
      <Dialog
        fullScreen
        open={openModal}
        TransitionComponent={Transition}
        sx={{
          '& .MuiDialog-paper': { backgroundColor: '#f2f8fc !important' },
        }}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: '#0d539e' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => handleOpenModal(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Dodaj zlecenie
            </Typography>
            <Button
              disabled={!isRecordValid()}
              autoFocus
              color="inherit"
              onClick={() => handleOpenModal(false)}
            >
              Zapisz
            </Button>
          </Toolbar>
        </AppBar>
        <Grid2
          container
          direction="column"
          gap={4}
          style={{ margin: '1rem 2rem', backgroundColor: '#f2f8fc' }}
        >
          <Box>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                '& .Mui-selected': {
                  color: '#0d539e !important',
                },
              }}
            >
              <Tab label="Dane zlecenia" />
              <Tab label="Dodatkowe informacje" />
            </Tabs>
          </Box>
          <CustomTabPanel value={activeTab} index={0}>
            <OrderDetailsTab methods={methods} orderIndex={orderIndex} />
          </CustomTabPanel>
          <CustomTabPanel value={activeTab} index={1}>
            <AdditionalInformationTab
              activeTab={activeTab}
              methods={methods}
              orderIndex={orderIndex}
            />
          </CustomTabPanel>
        </Grid2>
      </Dialog>
    )
  },
)

export default OrderFormModal
