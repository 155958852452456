import React, { useEffect, useState } from 'react'
import { Container, HeaderContainer } from '../../styles/styles'
import MainHeader from '../../components/MainHeader'
import DnDBoard from '../../components/DnD/DnDBoard'
import useProductionStore from '../../store/useProductionStore'
import useSocket from '../../hooks/useSocket'
import MSearchField from '../../components/material/MSearchField'

const PaintingBoardPage = () => {
  const [search, setSearch] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { paintingBoardColumns, getProductionBoardColumns } =
    useProductionStore()

  useSocket('painting_ticket_created', () =>
    getProductionBoardColumns('painting'),
  )
  useSocket('painting_ticket_updated', () =>
    getProductionBoardColumns('painting'),
  )

  useEffect(() => {
    if (!paintingBoardColumns.length) {
      setIsLoading(true)
    }
    getProductionBoardColumns('painting').finally(() => {
      setIsLoading(false)
    })
  }, [])

  return (
    <Container style={{ overflow: 'hidden' }}>
      <HeaderContainer container direction="row" justifyContent="space-between">
        <MainHeader title="Malowanie" />
      </HeaderContainer>
      {!isLoading && (
        <>
          <MSearchField
            sx={{ '&: div': { backgroundColor: 'transparent' } }}
            variant="standard"
            value={search}
            placeholder="Wyszukaj..."
            onChange={(e) => setSearch(e.target.value)}
          />
          <DnDBoard
            type="painting"
            items={paintingBoardColumns}
            search={search}
            style={{ height: 'calc(100% - 112px)', overflowY: 'auto' }}
          />
        </>
      )}
    </Container>
  )
}

export default PaintingBoardPage
