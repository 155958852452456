import React, { useEffect } from 'react'

import { Dispatch, SetStateAction } from 'react'
import { ProductionBoardRecord } from '../../types/production'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { splitTicket } from '../../utils/validation'
import MInput from '../material/MInput'

interface DnDSplitFormProps {
  activeItem: ProductionBoardRecord | null
  setSplitValue: Dispatch<SetStateAction<number>>
}

const DndSplitForm = ({ activeItem, setSplitValue }: DnDSplitFormProps) => {
  const { control, watch } = useForm({
    defaultValues: {
      splitValue: 1,
    },
    resolver: yupResolver(splitTicket),
    mode: 'all',
  })

  const watchedValue = watch('splitValue')

  useEffect(() => {
    setSplitValue(watchedValue)
  }, [watchedValue])

  return (
    <Controller
      name="splitValue"
      control={control}
      render={({ field: { value, onChange }, formState: { errors } }) => (
        <MInput
          fullWidth
          variant="outlined"
          label="Ilość elementów do podziału"
          type="number"
          value={value}
          onChange={onChange}
          helperText={errors.splitValue?.message}
          style={{ marginTop: '0.5rem' }}
          InputProps={{
            inputProps: {
              min: 1,
              max: activeItem?.quantity,
            },
          }}
        />
      )}
    />
  )
}

export default DndSplitForm
