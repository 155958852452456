import useDocumentsStore from '../store/useDocumentsStore'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
} from '@mui/material'
import React, { useState } from 'react'
import MInput from './material/MInput'

interface ConfirmPrintGrnDialogProps {
  open: boolean
  closeDialog: () => void
  documentId: number | null
}

const ConfirmPrintGrnDialog = ({
  documentId,
  closeDialog,
  open,
}: ConfirmPrintGrnDialogProps) => {
  const [numberOfCopy, setNumberOfCopy] = useState<string>('')
  const [confirmCount, setConfirmCount] = useState<boolean>(false)

  const { printDocument } = useDocumentsStore()

  const handlePrint = () => {
    if (documentId) {
      printDocument(documentId, Number(numberOfCopy))
      closeDialog()
    }
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Drukowanie</DialogTitle>
      <DialogContent style={{ padding: '1.25rem', width: '300px' }}>
        <Grid2 container direction="row" gap={2}>
          <MInput
            sx={{ width: '300px' }}
            label="Liczba kopii"
            type="number"
            value={numberOfCopy}
            onChange={(e) => setNumberOfCopy(e.target.value)}
            helperText="Liczba kopii jest automatycznie uzupełniana z danych formularza"
          />
          {Number(numberOfCopy) >= 10 && (
            <MInput
              sx={{ width: '300px' }}
              label="Potwierdź liczbę kopii"
              type="number"
              defaultValue=""
              onChange={(e) =>
                setConfirmCount(Number(numberOfCopy) === Number(e.target.value))
              }
            />
          )}
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog}>Anuluj</Button>
        <Button
          disabled={Number(numberOfCopy) >= 10 && !confirmCount}
          onClick={handlePrint}
        >
          Drukuj
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmPrintGrnDialog
