import { Autocomplete, styled, TextField } from '@mui/material'
import React, { CSSProperties } from 'react'
import { get } from 'lodash'

const CustomAutoComplete = styled(Autocomplete)(() => ({
  width: '300%',
  height: '90px',
  '& div': {
    backgroundColor: '#ffffff',
  },
}))

export interface AutoCompleteOption {
  label: string
  value: string
}

interface MAutocompleteProps {
  label: string
  options: AutoCompleteOption[]
  fullWidth?: boolean
  value: string
  onChange: (value: string) => void
  style?: CSSProperties
  disabled?: boolean
  freeSolo?: boolean
  isContractorSelect?: boolean
}

export default function MAutocomplete({
  style,
  label,
  value,
  disabled,
  options,
  onChange,
  fullWidth,
  freeSolo,
  isContractorSelect,
}: MAutocompleteProps) {
  const foundOption = options.find((opt) => opt.value === value)

  return (
    <CustomAutoComplete
      freeSolo={freeSolo}
      style={style ? style : fullWidth ? { width: '100%' } : {}}
      disabled={disabled}
      options={options}
      renderInput={(params) => <TextField {...params} label={label} />}
      value={foundOption ? foundOption : ''}
      inputValue={
        !isContractorSelect
          ? foundOption && !freeSolo
            ? foundOption.label
            : foundOption && freeSolo
              ? foundOption.label
              : value
          : undefined
      }
      onChange={(_, newValue) => {
        onChange((newValue as AutoCompleteOption).value)
      }}
      getOptionLabel={(option) => get(option, 'label', '')}
      getOptionKey={(option) => get(option, 'value', '')}
      disableClearable
      onInputChange={(_, newValue) => {
        if (freeSolo) {
          onChange(newValue)
        }
      }}
    />
  )
}
