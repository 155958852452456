import { Document } from '../types/document'
import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { Grn } from '../types/order'
import { NotificationService } from '../service/NotificationService'
import useOrdersStore from './useOrdersStore'

interface Store {
  documents: Document[]
  getDocuments: () => Promise<void>
  generateDocument: (
    ciNumber: string,
    signature: string,
    copies: number,
  ) => Promise<number | undefined>
  deleteDocument: (id: number) => void
  printDocument: (documentId: number, copies: number) => void
}

const useDocumentsStore = create<Store>((set) => ({
  documents: [],

  getDocuments: async () => {
    await callApi<{ data: Document[]; message: string }>(
      '/api/documents',
      'GET',
    ).then((res) => set(() => ({ documents: res.data })))
  },

  generateDocument: async (ciNumber, signature, copies) => {
    const { data: grn } = await useOrdersStore
      .getState()
      .getOrdersByCiNumber(ciNumber)

    const contractor: Partial<Grn> = {
      name: grn.name,
      postalCode: grn.postalCode,
      city: grn.city,
      road: grn.road,
      taxId: grn?.taxId || '',
      phoneNumber: grn.phoneNumber,
    }

    const res = await callApi<{ data: { document: Document } }>(
      '/api/documents',
      'POST',
      {
        contractor,
        elementsTable: grn.orders,
        ciNumber: grn.ciNumber,
        type: 'pdf',
        documentType: 'PZ',
        date: grn.date,
        contractorSignature: grn.signature,
        issuerSignature: signature,
        copies,
      },
    )

    set((state) => ({
      documents: [...state.documents, res.data.document],
    }))

    return res.data.document.id
  },

  deleteDocument: (id) =>
    callApi<{ data: { message: string } }>(
      `/api/documents/${id}`,
      'DELETE',
    ).then((res) => {
      NotificationService.showNotification(res.data.message, 'success')

      return set((state) => ({
        documents: state.documents.filter((doc) => doc.id !== id),
      }))
    }),

  printDocument: (documentId, copies) =>
    callApi(`/api/document/print/${documentId}`, 'POST', { copies }),
}))

export default useDocumentsStore
