import { OrderImage } from '../types/image'
import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { NotificationService } from '../service/NotificationService'
import { filter } from 'lodash'

interface Store {
  orderSketches: OrderImage[][]
  sketchesToUpload: File[][]
  addNewSketchesContainer: () => void
  removeSketchesContainer: (index: number) => void
  setSketchesToUpload: (sketchesToUpload: File[], index: number) => void
  getSelectedOrderSketches: (ciNumber?: string) => Promise<OrderImage[][]>
  getSketchesByOrderId: (
    ciNumber?: string,
    orderId?: number,
  ) => Promise<OrderImage[]>
  uploadSketch: (orderId: number, index: number) => Promise<number>
  deleteSketch: (orderIndex: number, sketchId?: number) => Promise<number>
  removeSketchToUpload: (sketchToDelete: File, index: number) => void
  printSketch: (sketchId: number) => void
}

const useSketchesStore = create<Store>((set, get) => ({
  orderSketches: [],
  sketchesToUpload: [],

  addNewSketchesContainer: () => {
    set((state) => {
      const newSketchesState = state.sketchesToUpload

      newSketchesState.push([])

      return {
        sketchesToUpload: newSketchesState,
      }
    })
  },

  removeSketchesContainer: (index) => {
    set((state) => {
      return {
        sketchesToUpload: filter(
          state.sketchesToUpload,
          (_images, i) => index !== i,
        ),
      }
    })
  },

  setSketchesToUpload: (sketchesToUpload, index) => {
    set((state) => {
      const newSketchesState = state.sketchesToUpload

      newSketchesState[index] = [
        ...state.sketchesToUpload[index],
        ...sketchesToUpload,
      ]

      return {
        sketchesToUpload: newSketchesState,
      }
    })
  },

  removeSketchToUpload: (sketchToDelete, index) => {
    set((state) => {
      const newSketchesState = state.sketchesToUpload

      newSketchesState[index] = filter(
        newSketchesState[index],
        (image) => image !== sketchToDelete,
      )

      return {
        sketchesToUpload: newSketchesState,
      }
    })
  },

  getSelectedOrderSketches: async (ciNumber) => {
    await callApi<{ data: OrderImage[][] }>(
      `/api/sketches/${ciNumber}`,
      'GET',
    ).then((res) => {
      set(() => ({
        orderSketches: res.data,
        sketchesToUpload: Array.from({ length: res.data.length }, () => []),
      }))
    })

    return get().orderSketches
  },

  getSketchesByOrderId: async (ciNumber, orderId) =>
    await callApi<{ data: OrderImage[] }>(
      `/api/sketches/${ciNumber}/${orderId}`,
      'GET',
    ).then((res) => {
      return res.data
    }),

  uploadSketch: async (orderId, index) => {
    if (get().sketchesToUpload.length > 0) {
      const formData = new FormData()

      get().sketchesToUpload[index].forEach((sketch) => {
        formData.append('sketches', sketch)
      })

      return await callApi<{ data: { message: string }; status: string }>(
        `/api/sketches/${orderId}`,
        'POST',
        formData,
      )
        .then((res) => {
          set(() => ({ sketchesToUpload: [] }))
          return res.status
        })
        .catch((error) => {
          NotificationService.showNotification(error.message, 'error')
          return error.status
        })
    }
  },

  deleteSketch: async (orderIndex, sketchId) =>
    await callApi<{ data: { message: string }; status: string }>(
      `/api/sketches/${sketchId}`,
      'DELETE',
    )
      .then((res) => {
        set((state) => {
          const newOrderSketches = state.orderSketches

          newOrderSketches[orderIndex] = filter(
            newOrderSketches[orderIndex],
            (sketch) => sketch.id !== sketchId,
          )

          return {
            orderSketches: newOrderSketches,
          }
        })
        return res.status
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),

  printSketch: (sketchId) => callApi(`/api/sketches/print/${sketchId}`, 'POST'),
}))

export default useSketchesStore
