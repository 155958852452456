import { Box, Grid2, IconButton, styled } from '@mui/material'

export const Container = styled(Box)(() => ({
  padding: '1rem',
  height: 'calc(100dvh - 2rem)',
  position: 'relative',
}))

export const HeaderContainer = styled(Grid2)(() => ({
  marginBottom: '1rem',
}))

export const Icon = styled(IconButton)(() => ({
  padding: '0.25rem 0.25rem',
}))

export const FieldWrapper = styled(Grid2)(() => ({
  flex: 1,
}))
