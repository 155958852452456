import React, { Dispatch, SetStateAction, useRef } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Grn, GrnOrder } from '../../types/order'
import { Grid2, Typography } from '@mui/material'
import MTable from '../material/MTable'
import { Column } from '../../types/customTableTypes'
import { FieldWrapper } from '../../styles/styles'
import dayjs from 'dayjs'
import SignaturePad, { SignaturePadHandle } from '../SignaturePad'
import { useParams } from 'react-router-dom'
import {
  translateFinishesValue,
  translateGlossLevelValue,
  translatePaintTypeValue,
  translateQuantityTypeValue,
  translateSandblasting,
} from '../../utils/helpers'
import MInput from '../material/MInput'

const columns: Column<Partial<GrnOrder>>[] = [
  {
    key: 'quantity',
    name: 'Ilość',
    align: 'left',
  },
  {
    key: 'quantityType',
    name: 'Typ',
    align: 'left',
    onRender: ({ quantityType }) => (
      <span>{translateQuantityTypeValue(quantityType)}</span>
    ),
  },
  {
    key: 'product',
    name: 'Nazwa towaru',
    align: 'left',
  },
  {
    key: 'ralColor',
    name: 'Kolor RAL',
    align: 'left',
  },
  {
    key: 'glossLevel',
    name: 'Stopień połysku',
    align: 'left',
    onRender: ({ glossLevel }) => (
      <span>{translateGlossLevelValue(glossLevel)}</span>
    ),
  },
  {
    key: 'finishing',
    name: 'Wykończenie',
    align: 'left',
    onRender: ({ finishing }) => (
      <span>{translateFinishesValue(finishing)}</span>
    ),
  },
  {
    key: 'paintType',
    name: 'Rodzaj farby',
    align: 'left',
    onRender: ({ paintType }) => (
      <span>{translatePaintTypeValue(paintType)}</span>
    ),
  },
  {
    key: 'base',
    name: 'Podkład',
    align: 'left',
    onRender: ({ base }) => <span>{base === 'yes' ? 'Tak' : 'Nie'}</span>,
  },
  {
    key: 'sandblasting',
    name: 'Piaskowanie',
    align: 'left',
    onRender: ({ sandblasting }) => (
      <span>{translateSandblasting(sandblasting)}</span>
    ),
  },
  {
    key: 'grinding',
    name: 'Szlifowanie',
    align: 'left',
    onRender: ({ grinding }) => (
      <span>{grinding === 'yes' ? 'Tak' : 'Nie'}</span>
    ),
  },
]

interface FormSummaryProps {
  methods: UseFormReturn<Grn>
  setSignature: Dispatch<SetStateAction<string>>
  grnCopies: number
  setGrnCopies: Dispatch<SetStateAction<number>>
}

const FormSummary = ({
  methods,
  setSignature,
  grnCopies,
  setGrnCopies,
}: FormSummaryProps) => {
  const { ciNumber } = useParams()
  const signatureRef = useRef<SignaturePadHandle>(null)

  const { watch, setValue } = methods
  const formData = watch()

  return (
    <Grid2 container direction="column" gap={4}>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <Grid2 container direction="row" gap={2}>
          <FieldWrapper style={{ flex: 1 }}>
            <Typography variant="h5">Kontrahent:</Typography>
            <Typography variant="body1">{formData.name}</Typography>
            <Typography variant="body1">NIP: {formData.taxId}</Typography>
            <Typography variant="body1">Tel: {formData.phoneNumber}</Typography>
            <Typography variant="body1">
              {formData.postalCode} {formData.city}
            </Typography>
            <Typography variant="body1">{formData.road}</Typography>
          </FieldWrapper>
          <FieldWrapper style={{ flex: 1 }}>
            <Typography variant="h5">Dane PZ:</Typography>
            <Typography variant="body1">PZ: {formData.ciNumber}</Typography>
            <Typography variant="body1">
              Data utworzenia: {dayjs(formData.date).format('YYYY-MM-DD HH:ss')}
            </Typography>
          </FieldWrapper>
        </Grid2>
      </Grid2>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <Typography variant="h5">Zlecenia:</Typography>
        <MTable columns={columns} data={formData.orders} isLoading={false} />
      </Grid2>
      <Grid2 container justifyContent="space-between" spacing={2}>
        <Grid2 container direction="column">
          <MInput
            style={{ marginTop: '1.25rem' }}
            label="Ilość kopii"
            required
            variant="outlined"
            type="number"
            value={grnCopies}
            onChange={(event) => setGrnCopies(Number(event.target.value))}
          />
        </Grid2>
        {!ciNumber && (
          <Grid2 container justifyContent="flex-end" spacing={2}>
            <Grid2>
              <SignaturePad
                ref={signatureRef}
                clearSignature={(signature) => {
                  setSignature(signature)
                }}
                onEnd={(signature) => {
                  setSignature(signature)
                }}
                title="Podpis wystawiającego"
              />
            </Grid2>
            <Grid2>
              <SignaturePad
                title="Podpis zlecającego"
                onEnd={(signature) =>
                  setValue('signature', signature, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
                clearSignature={(signature) =>
                  setValue('signature', signature, {
                    shouldDirty: true,
                    shouldValidate: true,
                  })
                }
              />
            </Grid2>
          </Grid2>
        )}
      </Grid2>
    </Grid2>
  )
}

export default FormSummary
