import React, { useEffect, useState } from 'react'
import { Grid2, IconButton, Typography } from '@mui/material'
import { Container, FieldWrapper, HeaderContainer } from '../../styles/styles'
import dayjs from 'dayjs'
import MTable from '../../components/material/MTable'
import { Grn, GrnOrder } from '../../types/order'
import useOrdersStore from '../../store/useOrdersStore'
import { Column } from '../../types/customTableTypes'
import {
  translateFinishesValue,
  translateGlossLevelValue,
  translatePaintTypeValue,
  translateQuantityTypeValue,
  translateSandblasting,
} from '../../utils/helpers'
import { useNavigate, useParams } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const columns: Column<Partial<GrnOrder>>[] = [
  {
    key: 'quantity',
    name: 'Ilość',
    align: 'left',
  },
  {
    key: 'quantityType',
    name: 'Typ',
    align: 'left',
    onRender: ({ quantityType }) => (
      <span>{translateQuantityTypeValue(quantityType)}</span>
    ),
  },
  {
    key: 'product',
    name: 'Nazwa towaru',
    align: 'left',
  },
  {
    key: 'ralColor',
    name: 'Kolor RAL',
    align: 'left',
  },
  {
    key: 'glossLevel',
    name: 'Stopień połysku',
    align: 'left',
    onRender: ({ glossLevel }) => (
      <span>{translateGlossLevelValue(glossLevel)}</span>
    ),
  },
  {
    key: 'finishing',
    name: 'Wykończenie',
    align: 'left',
    onRender: ({ finishing }) => (
      <span>{translateFinishesValue(finishing)}</span>
    ),
  },
  {
    key: 'paintType',
    name: 'Rodzaj farby',
    align: 'left',
    onRender: ({ paintType }) => (
      <span>{translatePaintTypeValue(paintType)}</span>
    ),
  },
  {
    key: 'base',
    name: 'Podkład',
    align: 'left',
    onRender: ({ base }) => <span>{base === 'yes' ? 'Tak' : 'Nie'}</span>,
  },
  {
    key: 'sandblasting',
    name: 'Piaskowanie',
    align: 'left',
    onRender: ({ sandblasting }) => (
      <span>{translateSandblasting(sandblasting)}</span>
    ),
  },
  {
    key: 'grinding',
    name: 'Szlifowanie',
    align: 'left',
    onRender: ({ grinding }) => (
      <span>{grinding === 'yes' ? 'Tak' : 'Nie'}</span>
    ),
  },
]

const DocumentDetailsPage = () => {
  const { ciNumber } = useParams()
  const navigate = useNavigate()
  const [grn, setGrn] = useState<Grn | null>(null)

  const { getOrdersByCiNumber } = useOrdersStore()

  const fetchGrn = async () => {
    if (ciNumber) {
      await getOrdersByCiNumber(ciNumber).then((res) => {
        if (res.data) {
          setGrn(res.data)
        }
      })
    }
  }

  useEffect(() => {
    fetchGrn()
  }, [])

  if (!grn) return null

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid2
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon style={{ color: '#000000' }} />
          </IconButton>
          <Typography variant="h5">PZ: {grn?.ciNumber}</Typography>
        </Grid2>
      </HeaderContainer>
      <Grid2 container direction="column" gap={4}>
        <Grid2 direction="column" container sx={{ flex: 1 }}>
          <Grid2 container direction="row" gap={2}>
            <FieldWrapper style={{ flex: 1 }}>
              <Typography variant="h5">Kontrahent:</Typography>
              <Typography variant="body1">{grn.name}</Typography>
              <Typography variant="body1">NIP: {grn.taxId}</Typography>
              <Typography variant="body1">Tel: {grn.phoneNumber}</Typography>
              <Typography variant="body1">
                {grn.postalCode} {grn.city}
              </Typography>
              <Typography variant="body1">{grn.road}</Typography>
            </FieldWrapper>
            <FieldWrapper style={{ flex: 1 }}>
              <Typography variant="h5">Dane PZ:</Typography>
              <Typography variant="body1">PZ: {grn.ciNumber}</Typography>
              <Typography variant="body1">
                Data utworzenia: {dayjs(grn.date).format('YYYY-MM-DD HH:ss')}
              </Typography>
            </FieldWrapper>
          </Grid2>
        </Grid2>
        <Grid2 direction="column" container sx={{ flex: 1 }}>
          <Typography variant="h5">Zlecenia:</Typography>
          <MTable columns={columns} data={grn.orders} isLoading={false} />
        </Grid2>
      </Grid2>
    </Container>
  )
}

export default DocumentDetailsPage
