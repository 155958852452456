type AllowedTransitions = {
  [key: string]: string[]
}

const roleBasedTransitions: { [role: string]: AllowedTransitions } = {
  admin: {
    new: ['new', 'planning'],
    planning: ['new', 'planning', 'painting', 'sandblasting'],
    painting: ['planning', 'painting', 'verify'],
    sandblasting: ['planning', 'sandblasting', 'done'],
    verify: ['verify', 'painting', 'done', 'rejected'],
    rejected: ['rejected', 'painting', 'done', 'verify'],
    done: ['done', 'toInvoiced', 'invoiced'],
    toInvoiced: ['done', 'toInvoiced', 'invoiced'],
    invoiced: ['invoiced'],
  },
  office: {
    new: ['new', 'planning'],
    planning: ['new', 'planning', 'painting', 'sandblasting'],
    painting: ['planning', 'painting', 'verify'],
    sandblasting: ['planning', 'sandblasting', 'done'],
    verify: ['verify', 'painting', 'done', 'rejected'],
    rejected: ['rejected', 'painting', 'done', 'verify'],
    done: ['done', 'toInvoiced', 'invoiced'],
    toInvoiced: ['done', 'toInvoiced', 'invoiced'],
    invoiced: ['invoiced'],
  },
  production: {
    planning: ['planning', 'painting', 'sandblasting'],
    painting: ['painting', 'verify'],
    sandblasting: ['sandblasting', 'done'],
  },
}

export const canDrag = (
  originalColumnId: string,
  newColumnId: string,
  userRole: string,
) => {
  const roleTransitions = roleBasedTransitions[userRole] || {}
  const allowedForRole = roleTransitions[originalColumnId] || []

  return allowedForRole.includes(newColumnId)
}
