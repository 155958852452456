import { create } from 'zustand'

interface Store {
  isOpen: boolean
  setIsOpen: (value?: boolean) => void
}

const useAppStore = create<Store>((set, get) => ({
  isOpen: true,
  setIsOpen: (value) => {
    set({ isOpen: value || !get().isOpen })
  },
}))

export default useAppStore
