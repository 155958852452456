import React, { useEffect, useState } from 'react'
import { Grid2, ImageList, ImageListItem } from '@mui/material'
import { UseFormReturn } from 'react-hook-form'
import { Grn } from '../../types/order'
import MUploadButton from '../material/MUploadButton'
import useImagesStore from '../../store/useImagesStore'
import { useParams } from 'react-router-dom'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { dataUrlToFile, getServerUrl } from '../../utils/helpers'
import { OrderImage } from '../../types/image'
import { map } from 'lodash'
import SketchDrawer from './SketchDrawer'
import useSketchesStore from '../../store/useSketchesStore'

interface AdditionalInformationTabProps {
  methods: UseFormReturn<Grn>
  orderIndex: number
  activeTab: number
}

interface UnifiedImages extends Partial<OrderImage> {
  file?: File
}

export default function AdditionalInformationTab({
  methods,
  orderIndex,
  activeTab,
}: AdditionalInformationTabProps) {
  const { getValues } = methods
  const { ciNumber } = useParams()
  const [imagesToDisplay, setImagesToDisplay] = useState<UnifiedImages[]>([])
  const [sketchesToDisplay, setSketchesToDisplay] = useState<UnifiedImages[]>(
    [],
  )
  const { orderImages, deleteImage, setImagesToUpload, removeImageToUpload } =
    useImagesStore()

  const {
    orderSketches,
    setSketchesToUpload,
    deleteSketch,
    removeSketchToUpload,
  } = useSketchesStore()

  const handleImagesChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files) {
      const filesArray = Array.from(files)
      setImagesToUpload(filesArray, orderIndex)
      const mappedFiles = filesArray.map((file) => ({
        file,
        type: file.type,
      })) as UnifiedImages[]
      setImagesToDisplay([...imagesToDisplay, ...mappedFiles])
    }
  }

  const handleSketchesChange = async (canvasData: string) => {
    const file = dataUrlToFile(canvasData)
    setSketchesToUpload([file], orderIndex)
    setSketchesToDisplay([...sketchesToDisplay, { file, type: file.type }])
  }

  const safelyRemoveImage = async (imageToDelete: UnifiedImages) => {
    if (imageToDelete.file) {
      const toRetain = imagesToDisplay.filter(
        (img) => img.file !== imageToDelete.file,
      )
      setImagesToDisplay(toRetain)
      removeImageToUpload(imageToDelete.file, orderIndex)
    } else {
      const toRetain = imagesToDisplay.filter(
        (image) => image.id !== imageToDelete.id,
      )
      setImagesToDisplay(toRetain)
      await deleteImage(orderIndex, imageToDelete.id)
    }
  }

  const safelyRemoveSketch = async (sketchToDelete: UnifiedImages) => {
    if (sketchToDelete.file) {
      const toRetain = sketchesToDisplay.filter(
        (sketch) => sketch.file !== sketchToDelete.file,
      )
      setSketchesToDisplay(toRetain)
      removeSketchToUpload(sketchToDelete.file, orderIndex)
    } else {
      const toRetain = sketchesToDisplay.filter(
        (sketch) => sketch.id !== sketchToDelete.id,
      )
      setSketchesToDisplay(toRetain)
      await deleteSketch(orderIndex, sketchToDelete.id)
    }
  }

  useEffect(() => {
    setImagesToDisplay(orderImages[orderIndex])
    setSketchesToDisplay(orderSketches[orderIndex])
  }, [ciNumber, activeTab])

  return (
    <Grid2 container direction="row" gap={4}>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <ImageList
          key="sketches"
          style={{
            width: '100%',
            maxHeight: 'calc(100dvh - 421px)',
            margin: '0',
            marginBottom: '1rem',
          }}
          cols={2}
        >
          {map(sketchesToDisplay, (sketch) => (
            <ImageListItem key={sketch.id}>
              <HighlightOffIcon
                onClick={() => safelyRemoveSketch(sketch)}
                style={{
                  position: 'absolute',
                  top: '0.25rem',
                  right: '0.25rem',
                  backgroundColor: '#0d539e',
                  color: '#ffffff',
                  borderRadius: '2rem',
                  cursor: 'pointer',
                }}
              />
              <img
                src={
                  sketch.file
                    ? URL.createObjectURL(sketch.file)
                    : getServerUrl(sketch.path)
                }
                alt={`${sketch.type}_${sketch.id}`}
                style={{ width: '100%', height: '150px' }}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <SketchDrawer
          label={`${getValues().orders[orderIndex].product} ${getValues().ciNumber}`}
          handleSketchesChange={handleSketchesChange}
        />
      </Grid2>
      <Grid2 direction="column" container sx={{ flex: 1 }}>
        <ImageList
          key="images"
          style={{
            width: '100%',
            maxHeight: 'calc(100dvh - 262px)',
            margin: '0',
            marginBottom: '1rem',
          }}
          cols={2}
        >
          {map(imagesToDisplay, (image) => (
            <ImageListItem key={image.id}>
              <HighlightOffIcon
                onClick={() => safelyRemoveImage(image)}
                style={{
                  position: 'absolute',
                  top: '0.25rem',
                  right: '0.25rem',
                  backgroundColor: '#0d539e',
                  color: '#ffffff',
                  borderRadius: '2rem',
                  cursor: 'pointer',
                }}
              />
              <img
                src={
                  image.file
                    ? URL.createObjectURL(image.file)
                    : getServerUrl(image.path)
                }
                alt={`${image.type}_${image.id}`}
                style={{ width: '100%', height: '150px' }}
              />
            </ImageListItem>
          ))}
        </ImageList>
        <MUploadButton onChange={handleImagesChange} />
      </Grid2>
    </Grid2>
  )
}
