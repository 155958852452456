import { create } from 'zustand'
import { callApi } from '../utils/callApi'
import { Grn } from '../types/order'
import { NotificationService } from '../service/NotificationService'

interface Store {
  orders: Grn[]
  getOrders: () => Promise<void>
  getOrdersByCiNumber: (
    ciNumber: string,
  ) => Promise<{ data: Grn; message: string }>
  addOrder: (
    data: object,
  ) => Promise<{ statusCode: number; ids: number[]; ciNumber: string }>
  updateOrder: (
    ciNumber: string,
    data: object,
  ) => Promise<{ statusCode: number; ids: number[]; ciNumber: string } | null>
  deleteOrder: (ciNumber: string, id: number) => void
  deleteAllOrdersByCiNumber: (ciNumber: string) => void
  getCiNumber: () => Promise<string>
}

const useOrdersStore = create<Store>((set, get) => ({
  orders: [],

  getOrders: async () => {
    await callApi<{ data: Grn[]; message: string }>('/api/orders', 'GET').then(
      (res) => {
        set(() => ({ orders: res.data }))
      },
    )
  },

  getOrdersByCiNumber: async (ciNumber) =>
    await callApi<{ data: Grn; message: string }>(
      `/api/orders/${encodeURIComponent(ciNumber)}`,
      'GET',
    ),

  addOrder: async (data) =>
    await callApi<{
      data: { ids: number[]; message: string; ciNumber: string }
      status: number
    }>('/api/orders', 'POST', data)
      .then((res) => {
        NotificationService.showNotification(res.data.message, 'success')
        return {
          statusCode: res.status,
          ids: res.data.ids,
          ciNumber: res.data.ciNumber,
        }
      })
      .catch((error) => {
        NotificationService.showNotification(error.message, 'error')
        return error.status
      }),

  updateOrder: async (ciNumber, data) =>
    await callApi<{
      data: { ids: number[]; message: string; ciNumber: string }
      status: number
    }>(`/api/orders/${encodeURIComponent(ciNumber)}`, 'PUT', data).then(
      (res) => {
        const index = get().orders.findIndex(
          (order) => order.ciNumber === ciNumber,
        )
        if (index !== -1) {
          NotificationService.showNotification(res.data.message, 'success')
          return {
            statusCode: res.status,
            ids: res.data.ids,
            ciNumber: res.data.ciNumber,
          }
        }
        return null
      },
    ),

  deleteAllOrdersByCiNumber: (ciNumber) =>
    callApi<{ data: { message: string } }>(
      `/api/orders/${encodeURIComponent(ciNumber)}`,
      'DELETE',
    ).then((res) => {
      NotificationService.showNotification(res.data.message, 'success')
    }),

  deleteOrder: (ciNumber, id) =>
    callApi<{ data: { message: string } }>(
      `/api/orders/${encodeURIComponent(ciNumber)}/${id}`,
      'DELETE',
    ).then((res) => {
      NotificationService.showNotification(res.data.message, 'success')
    }),

  getCiNumber: async () => {
    const res = await callApi<{ data: string }>(`/api/batchNumber`, 'GET')
    return res.data
  },
}))

export default useOrdersStore
