import React, { useEffect, useRef, useState } from 'react'
import {
  Grid2,
  IconButton,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useNavigate, useParams } from 'react-router-dom'
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import MButton from '../../components/material/MButton'
import { yupResolver } from '@hookform/resolvers/yup'
import { grnValidation } from '../../utils/validation'
import useOrdersStore from '../../store/useOrdersStore'
import ConfirmPrintLabelDialog from '../../components/ConfirmPrintLabelDialog'
import { Grn } from '../../types/order'
import { Check } from '@mui/icons-material'
import AddContractorForm from '../../components/AddOrderForm/AddContractorForm'
import useImagesStore from '../../store/useImagesStore'
import useSketchesStore from '../../store/useSketchesStore'
import useDocumentsStore from '../../store/useDocumentsStore'
import { find, forEach, isNumber, map } from 'lodash'
import RouteGuardDialog from '../../components/RouteGuardDialog'
import { Container, HeaderContainer } from '../../styles/styles'
import OrdersList from '../../components/AddOrderForm/OrdersList'
import OrderFormModal, {
  OrderFormModalHandle,
} from '../../components/AddOrderForm/OrderFormModal'
import FormSummary from '../../components/AddOrderForm/FormSummary'
import useAppStore from '../../store/useAppStore'

const FormContainer = styled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '2rem',
  height: 'calc(100% - 180px)',
  overflowY: 'auto',
}))

const steps = ['Podstawowe informacje', 'Zlecenia', 'Podsumowanie']

const StepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 0,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0d539e',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#0d539e',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 2,
    borderRadius: 2,
    ...theme.applyStyles('dark', {
      borderColor: theme.palette.grey[800],
    }),
  },
}))

const StepperConnectorIconRoot = styled('div')<{
  ownerState: { active?: boolean }
}>(({ theme }) => ({
  color: '#eaeaf0',
  display: 'flex',
  height: 2,
  alignItems: 'center',
  '& .QontoStepIcon-completedIcon': {
    color: '#0d539e',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  ...theme.applyStyles('dark', {
    color: theme.palette.grey[700],
  }),
  variants: [
    {
      props: ({ ownerState }) => ownerState.active,
      style: {
        color: '#0d539e',
      },
    },
  ],
}))

function StepperConnectorIcon(props: StepIconProps) {
  const { active, completed, className } = props

  return (
    <StepperConnectorIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </StepperConnectorIconRoot>
  )
}

const defaultOrder = {
  quantity: 0,
  quantityType: '',
  product: '',
  ralColor: '',
  glossLevel: '',
  finishing: '',
  paintType: '',
  base: '',
  sandblasting: '',
  grinding: '',
}

export default function AddOrderPage() {
  const navigate = useNavigate()
  const { ciNumber } = useParams()
  const { orders, addOrder, updateOrder } = useOrdersStore()
  const { setIsOpen } = useAppStore()
  const { uploadImage, addNewImagesContainer, getSelectedOrderImages } =
    useImagesStore()
  const { uploadSketch, addNewSketchesContainer, getSelectedOrderSketches } =
    useSketchesStore()
  const { generateDocument } = useDocumentsStore()
  const [openConfirmPrintDialog, setOpenConfirmPrintDialog] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [elementToPrint, setElementToPrint] = useState<string | null>(null)
  const [orderIndex, setOrderIndex] = useState<number>(0)
  const [signature, setSignature] = useState<string>('')
  const [grnCopies, setGrnCopies] = useState<number>(2)

  const editedObject = find(
    orders,
    (order) => order.ciNumber === decodeURIComponent(ciNumber as string),
  )

  const modalRef = useRef<OrderFormModalHandle | null>(null)

  const methods = useForm({
    defaultValues: {
      name: editedObject?.name || '',
      taxId: editedObject?.taxId || '',
      postalCode: editedObject?.postalCode || '',
      city: editedObject?.city || '',
      road: editedObject?.road || '',
      ciNumber: editedObject?.ciNumber || '',
      date: editedObject?.date || Date.now(),
      phoneNumber: editedObject?.phoneNumber || '',
      orders: editedObject?.orders || [],
    },
    resolver: yupResolver(grnValidation),
    mode: 'all',
  })

  const { handleSubmit, formState, watch, reset, control, trigger } = methods

  const { fields, append } = useFieldArray({
    control,
    name: 'orders',
    keyName: 'formOrderId',
  })

  const confirmPrintDialogControl = (order: string | null, isOpen: boolean) => {
    setElementToPrint(order)
    setOpenConfirmPrintDialog(isOpen)
  }

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const isValidFirstStep = () => {
    const [name, phoneNumber, postalCode, city, road, date, ciNumber] = watch([
      'name',
      'phoneNumber',
      'postalCode',
      'city',
      'road',
      'date',
      'ciNumber',
    ])

    return (
      name.length > 0 &&
      phoneNumber.length > 0 &&
      postalCode.length > 0 &&
      city.length > 0 &&
      road.length > 0 &&
      isNumber(date) &&
      ciNumber.length > 0
    )
  }

  const isValidSecondStep = () => {
    const orders = watch('orders')

    return orders && orders?.length > 0
  }

  const sign = watch('signature')

  useEffect(() => {
    if (ciNumber) {
      getSelectedOrderImages(encodeURIComponent(ciNumber))
      getSelectedOrderSketches(encodeURIComponent(ciNumber))
      trigger()
    }
  }, [ciNumber])

  return (
    <Container>
      <HeaderContainer
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <IconButton
          onClick={() => {
            setIsOpen(true)
            navigate(-1)
          }}
        >
          <ArrowBackIcon style={{ color: '#000000' }} />
        </IconButton>
        <Typography variant="h5">
          {ciNumber ? 'Edytuj zlecenie' : 'Dodaj zlecenie'}
        </Typography>
      </HeaderContainer>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<StepperConnector />}
      >
        {map(steps, (label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepperConnectorIcon}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
      <FormContainer>
        {activeStep === 0 ? (
          <AddContractorForm
            methods={methods as unknown as UseFormReturn<Grn>}
          />
        ) : activeStep === 1 ? (
          <OrdersList
            methods={methods as unknown as UseFormReturn<Grn>}
            setOrderIndex={(orderIndex: number) => {
              setOrderIndex(orderIndex)
              modalRef.current?.handleOpenModal(true)
            }}
          />
        ) : (
          <FormSummary
            setSignature={setSignature}
            methods={methods as unknown as UseFormReturn<Grn>}
            grnCopies={grnCopies}
            setGrnCopies={setGrnCopies}
          />
        )}
        {activeStep === 0 ? (
          <Grid2
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              width: '100%',
            }}
            container
            direction="row"
            justifyContent="flex-end"
          >
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              onClick={handleNext}
              disabled={!isValidFirstStep()}
            >
              Następny
            </MButton>
          </Grid2>
        ) : activeStep === 1 ? (
          <Grid2
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              gap: '1rem',
            }}
            container
            direction="row"
            justifyContent="flex-end"
          >
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              disabled={!!ciNumber}
              onClick={() => {
                append({ ...defaultOrder })
                addNewImagesContainer()
                addNewSketchesContainer()
                setOrderIndex(fields.length)
                modalRef.current?.handleOpenModal(true)
              }}
            >
              Dodaj nowy element
            </MButton>
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              onClick={handleBack}
            >
              Wróć
            </MButton>
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              onClick={handleNext}
              disabled={!isValidSecondStep()}
            >
              Następny
            </MButton>
          </Grid2>
        ) : (
          <Grid2
            style={{
              position: 'absolute',
              bottom: '1rem',
              right: '1rem',
              gap: '1rem',
            }}
            container
            direction="row"
            justifyContent="space-between"
          >
            <MButton
              style={{ width: 'fit-content' }}
              variant="contained"
              onClick={handleBack}
            >
              Wróć
            </MButton>
            <MButton
              style={{ width: 'fit-content' }}
              disabled={
                ciNumber
                  ? !formState.isValid
                  : !formState.isValid || !signature.length || !sign?.length
              }
              variant="contained"
              onClick={handleSubmit(async (values) => {
                if (ciNumber) {
                  const res = await updateOrder(editedObject!.ciNumber, values)

                  if (res?.statusCode === 200) {
                    forEach(res.ids, async (id, index) => {
                      await uploadImage(id, index)
                      await uploadSketch(id, index)
                    })
                    reset()
                    navigate(-1)
                  }
                } else {
                  const res = await addOrder(values)

                  if (res.statusCode === 200) {
                    forEach(res.ids, async (id, index) => {
                      await uploadImage(id, index)
                      await uploadSketch(id, index)
                    })
                    await generateDocument(res.ciNumber, signature, grnCopies)
                    confirmPrintDialogControl(res.ciNumber, true)
                    reset()
                  }
                }
              })}
            >
              Zapisz
            </MButton>
          </Grid2>
        )}
      </FormContainer>
      <ConfirmPrintLabelDialog
        open={openConfirmPrintDialog}
        closeDialog={() => {
          confirmPrintDialogControl(null, false)
          navigate(-1)
        }}
        ciNumber={elementToPrint}
      />
      <OrderFormModal
        orderIndex={orderIndex}
        methods={methods as unknown as UseFormReturn<Grn>}
        ref={modalRef}
      />
      <RouteGuardDialog methods={methods as unknown as UseFormReturn<Grn>} />
    </Container>
  )
}
